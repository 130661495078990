import * as React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Menu,
  styled,
  MenuItem,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import Loader from "../../../components/src/Loader.web";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MoreVertIcon from '@mui/icons-material//MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";
// Customizable Area End

import OrderManagementViewController from "./OrderManagementViewController";

// const OrderManagementView: React.FC<ViewProps> = (
export default class OrderManagementView extends OrderManagementViewController {
  // Customizable Area Start
  // Customizable Area End

  getPaidBackground = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'yes') {
      return '#0596691C';
    } else {
      return '#DC26261A';
    }
  }

  getPaidTextColor = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'yes') {
      return '#059669';
    } else {
      return '#DC2626';
    }
  }

  getStatusBackground = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'completed') {
      return '#0596691C';
    } else {
      return '#DC26261A';
    }
  }

  getStatusTextColor = (paid: string): string => {
    if (paid.toLocaleLowerCase() === 'completed') {
      return '#059669';
    } else {
      return '#DC2626';
    }
  }
  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl);
    return (
      //Merge Engine DefaultContainer
      <HeaderVendorFooter navigation={this.props.navigation}>
        <Loader loading={this.state.loading} />
        <CustomBox>
          <Box className="orderManagementBox">
            <Grid container className="mainGrid" spacing={6}>
              <Grid item xs={12}>
                <Box className="orderManagementInnerBox">
                  <Box width={"100%"} paddingBottom={"25px"} marginBottom={"10px"} borderBottom={"1px solid #A8A29E"}>
                    <Typography className="orderManagementText">Order Management</Typography>
                  </Box>

                  <Box sx={{ width: "90vw", overflow: "auto", borderRadius: "12px", border: "1px solid #d3d3d3" }}>
                    <Table aria-label="simple table" className="table" style={{ border: "none" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className="tableHeaderText">#</TableCell>
                          <TableCell align="center" className="tableHeaderText">Order Id</TableCell>
                          <TableCell align="center" className="tableHeaderText">Date</TableCell>
                          <TableCell align="center" className="tableHeaderText">Name</TableCell>
                          <TableCell align="center" className="tableHeaderText">Price</TableCell>
                          <TableCell align="center" className="tableHeaderText">Paid</TableCell>
                          <TableCell align="center" className="tableHeaderText">Address</TableCell>
                          <TableCell align="center" className="tableHeaderText">Status</TableCell>
                          <TableCell align="center" className="tableHeaderText">Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      {this.state.ordersData.length > 0 ?
                        <TableBody>
                          {this.state.ordersData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="center" className="tableRowText">{index + 1}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.code}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.date}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.quantity}</TableCell>
                              <TableCell align="center" className="tableRowText">{row.price}</TableCell>
                              <TableCell align="center" className="tableRowText">
                                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                  <Box sx={{ width: "max-content", borderRadius: "8px", padding: "10px 15px", background: this.getPaidBackground(row.paid), color: this.getPaidTextColor(row.paid) }}>
                                    {row.paid}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="center" className="tableRowText">{row.location}</TableCell>
                              <TableCell align="center" className="tableRowText">
                                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                  <Box sx={{ width: "max-content", borderRadius: "8px", padding: "10px 15px", background: this.getStatusBackground(row.status), color: this.getStatusTextColor(row.status) }}>
                                    {row.status}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="center" className="tableRowText">
                                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <ChatBubbleOutlineIcon />
                                    <IconButton data-test-id={`ViewMoreIcon${index}`} onClick={this.handleClick}>
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Box>
                                  <Menu
                                    anchorEl={this.state.anchorEl}
                                    open={open}
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <MenuItem data-test-id={`EditOrderButton${index}`} onClick={this.handleClose}><EditIcon style={{ marginRight: "10px", color: "#78716C" }} /> Edit</MenuItem>
                                    <MenuItem data-test-id={`MarkCompleteButton${index}`} onClick={() => this.handleMarkComplete(row.id)}><CheckCircleIcon style={{ marginRight: "10px", color: "#78716C" }} /> Mark complete</MenuItem>
                                    <MenuItem data-test-id={`DownloadButton${index}`} onClick={this.handleClose}><DownloadIcon style={{ marginRight: "10px", color: "#78716C" }} /> Download</MenuItem>
                                  </Menu>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        :
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={9} style={{ textAlign: "center", height: "300px" }}>
                              <Typography>No Orders Found!</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      }
                    </Table>
                  </Box>

                </Box>
              </Grid>
            </Grid>
          </Box>
        </CustomBox>
      </HeaderVendorFooter>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
};

// Customizable Area Start
const CustomBox = styled(Box)({
  padding: "0 4% 4% 4%",
  overflow: "hidden",
  "& .mainContainer": {
    display: "flex",
    justifyConetent: "center",
    alignItems: "center",
    width: "100vw",
    height: "auto",
  },
  "& .tableHeaderText": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C",
  },
  "& .tableRowText": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#44403C",
    borderBottom: "transparent"
  },
  "& .overviewBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90vw"
  },
  "& .overviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .overviewSelect": {
    width: "124px",
    height: "40px",
    color: "#6F767E"
  },
  "& .cardBox": {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    paddingTop: "2rem",
    flexWrap: "wrap",
    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .orderManagementBox": {
    flexWrap: "Wrap",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    marginTop: "0px",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .orderManagementInnerBox": {
    maxWidth: "100%",
    gap: "1rem",
    padding: "1rem 0rem",
    display: "flex",
    flexDirection: "column",
  },
  "& .orderManagementText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewBox": {
    paddingTop: "1rem",
    "@media (max-width: 1510px)": {
      paddingLeft: "0rem",
      paddingRight: "0rem"
    },
    "@media (max-width: 768px)": {
      width: "90vw",
      paddingLeft: "2rem",
      paddingRight: "2rem"
    },
  },
  "& .reviewInnerBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .reviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .table": {
    border: "1px solid lightgrey",
    borderRadius: "12px",
  },
  "& .seeallBtn": {
    color: "#7F1187",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewCard": {
    borderBottom: "1px solid #CBD5E1",
    paddingBottom: "1rem",
  },
  "& .reviewInnerCard": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem"
  },
  "& .reviewCardname": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .reviewCardTime": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .commentBox": {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "1rem"
  },
  "& .commentText": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .rating": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C"
  },
  "& .reviewDescription": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#A8A29E",
    padding: "0.7rem 0"
  }

})
// Customizable Area End