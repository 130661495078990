import React, { useState, useRef } from 'react';
import { TextField, Grid, styled, Box } from '@mui/material';

const OtpInput = ({ onChange }: any) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputs: any = useRef([]);

  const handleChange = (index: any, event: any) => {
    const { value } = event.target;
    if (/\d/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (onChange) {
        onChange(newOtp.join(''));
      }
      if (value && index < 5) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index: any, event: any) => {
    if (event.key === 'Backspace' && !otp[index]) {
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text').slice(0, 6);
    if (/^\d+$/.test(pasteData)) {
      const newOtp = pasteData.split('').concat(new Array(6 - pasteData.length).fill(''));
      setOtp(newOtp);
      if (onChange) {
        onChange(newOtp.join(''));
      }
      if (newOtp[5]) {
        inputs.current[5].focus();
      } else {
        const nextEmptyIndex = newOtp.findIndex((digit: any) => digit === '');
        if (nextEmptyIndex !== -1) {
          inputs.current[nextEmptyIndex].focus();
        }
      }
    }
  };

  return (
    <StyleWrapper>
      <Grid container spacing={1}>
        {otp.map((digit, index) => (
          <Grid xs={2} item key={index} sx={{ display: "flex", flexDirection: "row" }}>
            <TextField
              inputRef={(ref) => (inputs.current[index] = ref)}
              value={digit}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={index === 0 ? handlePaste : undefined}
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center', fontSize: '16px', fontFamily: "poppins", fontWeight: 400 },
              }}
              variant="outlined"
              size="small"
            />
          </Grid>
        ))}
      </Grid>
    </StyleWrapper>
  );
};

const StyleWrapper = styled(Box)({
  marginBottom: "16px",
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .MuiInputBase-root.Mui-error": {
    borderRadius: "8px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "0px",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiFormHelperText-root": {
    color: "#DC2626",
    margin: "8px 0 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171 !important",
    borderWidth: "1px !important",
    borderStyle: "solid",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  {
    borderRadius: "8px",
    borderWidth: "1px",
  },
});

export default OtpInput;
