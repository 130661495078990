import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { Message } from "framework/src/Message";
import { logo } from "./assets";

interface RazorpayOrderResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      key: string;
      amount: number;
      status: string;
      razorpay_order_id: string;
      receipt: string;
      account_id: number;
      order_id: number;
      entity: string;
      amount_paid: string;
      amount_due: string;
      currency: string;
      notes: any[];
      payment_method: string | null;
      order: {
        id: string;
        type: string;
        attributes: Record<string, any>;
      };
    };
  };
}

interface VerifyPaymentSignatureResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      status: boolean;
      rpay_order_id: string;
      razorpay_payment_id: string;
      razorpay_signature: string;
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isLoading: boolean;
  orderData: RazorpayOrderResponse | null;
  loaderMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderRazorPayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createOrderId: string = "";
  verifyPaymentSignatureId: string = "";
  rzp: any;
  options: any;
  script: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      orderData: null,
      loaderMessage: 'Do not refresh your browser, close or go back.',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.createOrderId:
          this.handleCreateOrderResponse(webResponseJson);
          break;

        case this.verifyPaymentSignatureId:
          this.handleVerifyPaymentSignatureResponse(webResponseJson);
          break;

        default:
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let cartId = await this.props.navigation.getParam("id", "");
    if (cartId) {
      this.createOrderApi(parseInt(cartId));
    }
  }

  loadScript = () => {
    this.script = document.createElement('script');
    this.script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    this.script.async = true;
    this.script.onload = this.initializeRazorpay;
    document.body.appendChild(this.script);
  }

  initializeRazorpay = () => {
    const { orderData } = this.state;
    const data = orderData?.data.attributes;
    const customer = data?.order.attributes.customer.attributes;
    this.options = {
      key: data?.key,
      amount: data?.amount,
      currency: data?.currency,
      name: 'Happiffie',
      description: 'Product purchase transaction',
      image: logo,
      order_id: data?.razorpay_order_id,
      handler: this.handlePaymentSuccess,
      modal: {
        ondismiss: this.handlePaymentCancel,
      },
      prefill: {
        name: customer?.full_name,
        email: customer?.email,
        contact: customer?.full_phone_number,
      },
      theme: {
        color: '#7F1187',
      },
    }

    this.rzp = new window.Razorpay(this.options);
    this.rzp.on('payment.failed', this.handlePaymentFailure);
  };

  handlePaymentSuccess = (response: any) => {
    this.verifyPaymentSignatureApi(response);
  };

  handlePaymentFailure = () => {
    console.log("payment is failed!");
  };

  handlePaymentCancel = () => {
    this.props.navigation.navigate("Cart");
  };

  openRazorpay = () => {
    this.rzp.open();
  };

  createOrderApi = async (cartId: number) => {
    const requestBody = {
      "data": {
        "order_id": cartId
      }
    }

    this.setState({ isLoading: true });
    this.createOrderId = await apiCall({
      endPoint: configJSON.createOrderEndpoint,
      method: configJSON.postApiMethod,
      contentType: 'application/json',
      body: JSON.stringify(requestBody),
    });
  }

  handleCreateOrderResponse = (response: RazorpayOrderResponse) => {
    if (response.data) {
      this.setState({ orderData: response }, () => {
        this.loadScript();
        setTimeout(() => {
          this.openRazorpay();
        }, 1500);
      });
    } else {
      this.setState({ orderData: null });
    }
  }

  verifyPaymentSignatureApi = async (data: any) => {
    const requestBody = {
      razorpay_payment_id: data.razorpay_payment_id,
      razorpay_order_id: data.razorpay_order_id,
      razorpay_signature: data.razorpay_signature,
    }

    this.setState({ isLoading: true, loaderMessage: 'Redirecting back to merchant...' });
    this.verifyPaymentSignatureId = await apiCall({
      endPoint: configJSON.verifyPaymentSignature,
      method: configJSON.postApiMethod,
      contentType: 'application/json',
      body: JSON.stringify(requestBody),
    });
  }

  handleVerifyPaymentSignatureResponse = (response: VerifyPaymentSignatureResponse) => {
    if (response.data && response.data.attributes.status) {
      const orderId = response.data.attributes.rpay_order_id;
      this.props.navigation.navigate("PaymentSuccess", {orderId});
    }
  }
  // Customizable Area End
}
