import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Rating, Button, Grid } from "@mui/material";
// Customizable Area End

import MyPurchasesController, { Props } from "./MyPurchasesController";
import HeaderFooterLayout from "../../../../components/src/HeaderFooterLayout";
import { thumbnail } from "../assets";
import { AiOutlinePlus } from "react-icons/ai";

export default class MyPurchases extends MyPurchasesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        const { currentPath } = this.state;

        return (
            // Customizable Area Start
            <HeaderFooterLayout navigation={this.props.navigation}>
                <StyleWrapper>
                    <Grid width={"100%"} container marginBottom={"40px"}>
                        <Grid item xs={12} className="headerGrid">
                            <Typography component={"h3"} className={currentPath === '/mybookings' ? "titleActive" : "mainTitle"} onClick={this.handleGotoMyBookings} >My Bookings</Typography>
                            <Typography component={"h3"} className={currentPath === '/mypurchases' ? "titleActive" : "mainTitle"} onClick={this.handleGotoMyPurchases} >My Purchases</Typography>
                        </Grid>
                    </Grid>
                    <Box border={"1px solid #D9D9D9"} width={"100%"} borderRadius={"12px"} padding={"20px"}>
                        <Grid width={"100%"} container spacing={3}>
                            <Grid xl={2} lg={2} md={2} sm={12} xs={12} item>
                                <img style={{ width: "100%", borderRadius: "8px", objectFit: "cover" }} src={thumbnail} />
                            </Grid>
                            <Grid xl={8} lg={8} md={8} sm={12} xs={12} item flexDirection={"column"} display={"flex"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography className="productTitle">Madhuram women'S Georgette Lehenga</Typography>
                                    <Typography className="vendorText">
                                        Kothari brothers
                                        <Typography className="locationText" component={"span"}>Mumbai</Typography>
                                    </Typography>
                                    <Typography className="orderDate">
                                        Order Date : 26 Oct, 2024
                                    </Typography>
                                    <Typography className="productPrice">
                                        ₹ 6,000
                                    </Typography>
                                </Box>
                                <Box paddingBottom={"15px"}>
                                    <Typography component={"h6"} className="reviewTitle">Write a Ratings</Typography>
                                    <Box width={"100%"} display={"flex"} columnGap={"30px"} flexDirection={"row"} alignItems={"center"}>
                                        <Rating value={8} precision={1} max={10} size="medium" />
                                        <Typography component={"span"} className="outOfText">out of -/10</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xl={2} lg={2} md={2} sm={12} xs={12} item display={"flex"} justifyContent={"center"}>
                                <Box className="buttonsGroup">
                                    <Button className="addReviewButton">Add Review <AiOutlinePlus /> </Button>
                                    <Button className="buttonStyle">Buy again</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </StyleWrapper >
            </HeaderFooterLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyleWrapper = styled(Box)({
    padding: "4%",
    '& .headerGrid': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        columnGap: "40px",
        borderBottom: "1px solid #A8A29E"
    },
    '& .mainTitle': {
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: 400,
        marginBottom: "20px",
        cursor: "pointer",
        color: "#292524",
    },
    '& .titleActive': {
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: 600,
        marginBottom: "20px",
        cursor: "pointer",
        color: "#7F1187",
    },
    '& .productTitle': {
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "32px",
        fontWeight: 500,
        marginBottom: "10px",
        color: "#000000",
    },
    '& .orderDate': {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "12px",
        color: "#000000",
        marginBottom: "20px",
        lineHeight: "24px",
    },
    '& .buttonsGroup': {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "15px",
    },
    '& .vendorText': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#000000",
        marginBottom: "10px",
    },
    '& .locationText': {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#000000",
        marginBottom: "10px",
        marginLeft: "15px",
    },
    '& .productPrice': {
        fontFamily: "Poppins",
        fontSize: "32px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#801188",
        marginBottom: "20px",
    },
    '& .subscriptionPlan': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#3A3A3A",
        marginBottom: "10px",
    },
    '& .reviewTitle': {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#78716C",
        marginBottom: "10px",
    },
    "& .outOfText": {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "34px",
        color: "#646464",
    },
    "& .buttonStyle": {
        color: "#7F1187",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#FFF",
        border: "1px solid #7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "155px",
        cursor: "pointer",
        "&:hover": {
            background: "#FFF"
        },
        "&:disabled": {
            background: "#808080",
            color: "#ababab",
        }
    },
    "& .addReviewButton": {
        color: "#7F1187",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#FFF",
        border: "none",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "155px",
        cursor: "pointer",
        "&:hover": {
            background: "#FFF"
        },
    }
});
// Customizable Area End
