import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/utils";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  pathName: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyBookingsController extends BlockComponent<Props, S, SS> {
  getMyBookingsId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: '',
      pathName: '',
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.setState({ pathName: location.pathname });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webApiRequestCallId === this.getMyBookingsId) {
        this.handleGetMyBookingsResponse(webResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleRatingChange = (event: any) => {
    const stars = parseInt(event.target.value);
    console.log(stars);
  }

  getMyBookingsData = async () => {
    this.getMyBookingsId = await apiCall({
      endPoint: `${configJSON.reviewRatingEndpoint}`,
      method: configJSON.httpGetMethod,
    });
  }

  handleGetMyBookingsResponse = (response: any) => {
    console.log("Bookings Response: ", response);
  }

  handleGotoMyPurchases = () => {
    this.props.navigation.navigate("MyPurchases")
  }

  handleGotoMyBookings = () => {
    this.props.navigation.navigate("MyBookings")
  }
  // Customizable Area End
}
