import React, { useEffect } from "react";
import { styled, Box } from "@mui/material";
import Header from "./Header.web";
import Footer from "./Footer.web";
import { scrollToTop } from "./utils";
import { getStorageData } from "../../framework/src/Utilities";

const backgroundImage = require("./assets/background_image.png");

interface IHeaderFooterLayout {
  navigation: any;
  children: React.ReactNode;
  isFooterShow?: boolean;
}

const HeaderFooterLayout: React.FC<IHeaderFooterLayout> = ({
  navigation,
  children,
  isFooterShow = true,
}) => {

  useEffect(() => {
    (async () => {
      const allowedForVendors = ['faq', 'contact-us', 'profile', 'settings'];
      const restrictedWihoutLogin = ['profile', 'settings', 'wishlist', 'mybookings', 'mypurchases'];

      const role = await getStorageData("role");
      const token = await getStorageData("authToken");
      const path = window.location.pathname.replace(/\//g, '').toLocaleLowerCase();

      if (!token && restrictedWihoutLogin.includes(path)) {
        navigation.navigate("LandingPageWeb");
      }

      if (!allowedForVendors.includes(path) && token && role && role.toLocaleLowerCase() === 'vendor') {
        navigation.navigate("Dashboard");
      }
    })();
    scrollToTop();
  }, []);

  return (
    <HeaderFooterLayoutStyle data-test-id="InspirationStyle">
      <Header className="header" navigation={navigation} isOtherPage />
      <Box className="body-data">
        <Box className="children-auto-height">{children}</Box>
        {isFooterShow && <Footer className="footer" />}
      </Box>
    </HeaderFooterLayoutStyle>
  );
};

export default HeaderFooterLayout;

const HeaderFooterLayoutStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  "& .header": {
    flex: "0 0 auto",
  },
  "& .children-auto-height": {
    flex: "1 1 auto",
  },
  "& .body-data": {
    backgroundImage: `url(${backgroundImage})`,
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  "& .footer": {
    flex: "0 0 auto",
  },
}));
