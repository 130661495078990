// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";
import eventEmitter from "../../../components/src/EventEmitter";
import { productDefaultImage } from "../../../blocks/categoriessubcategories/src/assets";

export const configJSON = require("./config");

export interface WishlistData {
  data: WishlistItem[];
}

export interface WishlistItem {
  id: string;
  type: string;
  attributes: WishlistAttributes;
}

export interface WishlistAttributes {
  id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  item: ItemData;
}

export interface ItemData {
  data: ProductData;
}

export interface ProductData {
  id: string;
  type: string;
  attributes: ProductDataAttributes;
}

export interface ProductDataAttributes {
  id: number;
  name: string;
  SKU: string;
  category: Category;
  description: string;
  fabric_details: string;
  price: string;
  available_quantity: number;
  media_files: MediaFile[];
  functions: Function[];
  service_locations: ServiceLocations;
  product_variants: ProductVariant[];
  custom_fields: Record<string, any>;
  created_at: string;
  is_published: boolean;
  product_faqs: ProductFaqs[];
  size_chart: string;
  is_available: boolean;
  is_deleted: boolean;
  coupon?: Coupon;
}

export interface Category {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

export interface CategoryAttributes {
  id: number;
  name: string;
  description: string | null;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  updated_at: string;
  sub_categories: any[];
  selected_sub_categories: any | null;
  image: string;
}

export interface MediaFile {
  url: string;
  filename: string;
  content_type: string;
  byte_size: number;
}

export interface Function {
  id: string;
  type: string;
  attributes: FunctionAttributes;
}

export interface FunctionAttributes {
  id: number;
  name: string;
  function_image: string;
  description: string;
}

export interface ServiceLocations {
  data: ServiceLocation[];
}

export interface ServiceLocation {
  id: string;
  type: string;
  attributes: ServiceLocationAttributes;
}

export interface ServiceLocationAttributes {
  id: number;
  location_name: string;
}

export interface ProductVariant {
  id: string;
  type: string;
  attributes: ProductVariantAttributes;
}

export interface ProductVariantAttributes {
  id: number;
  product_size: ProductSize;
  product_color: ProductColor;
  available_count: number;
}

export interface ProductSize {
  id: string;
  type: string;
  attributes: ProductSizeAttributes;
}

export interface ProductSizeAttributes {
  id: number;
  size_label: string;
  measurement: number;
}

export interface ProductColor {
  id: string;
  type: string;
  attributes: ProductColorAttributes;
}

export interface ProductColorAttributes {
  id: number;
  name: string;
  color_image: string | null;
}

export interface Coupon {
  id: string;
  type: string;
  attributes: CouponAttributes;
}

export interface CouponAttributes {
  id: number;
  cost_type: string;
  coupon_code: string;
  exp_date: string;
  min_order_value: string;
  max_discount: string;
  discount_percentage: number;
  terms_policies: string;
  is_expired: boolean;
  amount: string | null;
}

export interface ProductFaqs {
  id: string;
  type: string;
  attributes: ProductFaqAttributes;
}

export interface ProductFaqAttributes {
  id: number;
  question: string;
  answer: string;
  open?: boolean;
}

export interface Props {
  navigation: any;
}

interface S {
  isLoading: boolean;
  wishlistArray: [];
  wishlistAllArray: WishlistItem[];
  token: string;
  clickedProductId: number;
  productCardLoader: boolean;
  openDeleteConfirmationModal: boolean;
  deleteProductId: number;
  openAddToCartSuccessModal: boolean;
  cartProduct: ProductDataAttributes | null;
}

interface SS {

}

export default class WishlistControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  wishlistApiCallId: string = "";
  toggleWishlistApiCallId: string = "";
  wishlistAllApiCallId: string = "";
  addProductToCartId: string = "";
  wishlistDeleteApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      wishlistArray: [],
      wishlistAllArray: [],
      token: '',
      clickedProductId: 0,
      productCardLoader: false,
      openDeleteConfirmationModal: false,
      deleteProductId: 0,
      openAddToCartSuccessModal: false,
      cartProduct: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAllWishlist();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (webApiRequestCallId) {
        case this.wishlistAllApiCallId:
          this.handleGetAllWishlistResponse(webResponseJson);
          break;

        case this.addProductToCartId:
          this.handelAddToCartResponse(webResponseJson);
          break;

        case this.wishlistDeleteApiId:
          this.handelDeleteWishlistResponse(webResponseJson);
          break;

        default:
          break;
      }
    }
  }

  getAllWishlist = async () => {
    this.setState({ isLoading: true });
    this.wishlistAllApiCallId = await apiCall({
      endPoint: configJSON.getWishlist,
      method: configJSON.validationApiMethodType,
      contentType: 'application/json',
    });
  }

  handleGetAllWishlistResponse = (response: WishlistData) => {
    if (response?.data) {
      let arrayholder = response.data;
      if (arrayholder && arrayholder.length !== 0) {
        this.setState({ wishlistAllArray: arrayholder });
      }
    } else {
      this.setState({ wishlistAllArray: [] });
    }
    this.setState({ isLoading: false });
  }

  openProductSummary = (productId: number) => {
    this.props.navigation.navigate("ProductSummary", { id: productId });
  }

  handleAddToCart = async (event: any, productId: number) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    const { wishlistAllArray } = this.state;
    const wishlistProducts = wishlistAllArray.map((item) => {
      return item.attributes.item.data.attributes;
    })
    const product = wishlistProducts.find((item) => item.id == productId);
    this.setState({ cartProduct: product || null });
    const variant = product?.product_variants[0];

    if (variant && variant.id) {
      this.addToCartApiCall(variant, productId);
    } else {
      toast.error('No product variant available', { position: 'bottom-right' });
    }
  }

  addToCartApiCall = async (variant: any, productId: number) => {
    const requestBody = {
      cart: {
        product_id: productId,
        product_size_id: parseInt(variant.attributes.product_size.id),
        product_color_id: parseInt(variant.attributes.product_color.id),
        quantity: 1
      }
    }
    this.setState({ clickedProductId: productId, productCardLoader: true });
    this.addProductToCartId = await apiCall({
      endPoint: configJSON.addToCartEndpoint,
      method: configJSON.exampleAPiMethod,
      contentType: 'application/json',
      body: JSON.stringify(requestBody),
    });
  }

  handelAddToCartResponse = (response: { data: any, error: string, errors: any[], message: string }) => {
    if (response?.data) {
      eventEmitter.emit('updateCartCounter', 1, []);
      this.handleOpenAddToCartSuccessModal();
      toast.success('Product added to cart', { position: 'bottom-right' });
    }
    if (response?.message) {
      toast.warning(response.message, { position: 'bottom-right' });
    }
    if (response?.error) {
      toast.error(response.error, { position: 'bottom-right' });
    }
    if (response?.errors) {
      toast.error(response.errors[0]?.token || 'Failed to add product to cart', { position: 'bottom-right' });
    }
    this.setState({ clickedProductId: 0, productCardLoader: false });
  }

  handleDeleteFromWishlist = async () => {
    const { deleteProductId } = this.state;
    this.setState({ openDeleteConfirmationModal: false, isLoading: true })
    this.wishlistDeleteApiId = await apiCall({
      endPoint: configJSON.getWishlist + `/${deleteProductId}`,
      method: configJSON.deleteAPiMethod,
      contentType: 'application/json',
    });
  }

  handelDeleteWishlistResponse = (responseJson: { data: any, error: string, errors: any[], message: string }) => {
    if (responseJson?.data) {
      toast.success('Product deleted from wishlist', { position: 'bottom-right' });
    }
    if (responseJson?.errors) {
      toast.error(responseJson.errors[0]?.token || 'Failed to delete product from wishlist', { position: 'bottom-right' });
    }
    if (responseJson?.error) {
      toast.error(responseJson.error, { position: 'bottom-right' });
    }
    if (responseJson?.message) {
      toast.warning(responseJson.message, { position: 'bottom-right' });
    }
    this.setState({ isLoading: false, clickedProductId: 0, deleteProductId: 0 });
    this.getAllWishlist();
  }

  handleOpenDeleteConfirmationModal = (event: any, productId: number) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.setState({ deleteProductId: productId, openDeleteConfirmationModal: true });
  }

  handleCloseDeleteConfirmationModal = () => {
    this.setState({ openDeleteConfirmationModal: false });
  }

  handleOpenAddToCartSuccessModal = () => {
    this.setState({ openAddToCartSuccessModal: true });
  }

  handleCloseAddToCartSuccessModal = () => {
    this.setState({ openAddToCartSuccessModal: false, cartProduct: null });
  }

  handleGotoCart = () => {
    this.handleCloseAddToCartSuccessModal();
    this.props.navigation.navigate("Cart");
  }

  getWishlistProductImage = (files: MediaFile[]): string => {
    let image = productDefaultImage;
    if (files.length > 0) {
      image = files[0].url;
    }
    return image;
  }
}
// Customizable Area End
