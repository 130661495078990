import React from "react";

// Customizable Area Start
import { Box, CircularProgress, styled, Typography } from "@mui/material";
declare global {
    interface Window {
        Razorpay: any;
    }
}

// Customizable Area End
import OrderRazorPayController, { Props } from './OrderRazorPayController.web';
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";

export default class OrderRazorPay extends OrderRazorPayController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { isLoading, loaderMessage } = this.state;
        return (
            // Customizable Area Start
            <StyleWrapper >
                <HeaderFooterLayout navigation={this.props.navigation}>
                    <Box className="mainContainer">
                        {isLoading &&
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={"20px"}>
                                <CircularProgress value={10} size={40} />
                                <Typography className="loaderText">Please wait...</Typography>
                                <Typography className="loaderText">{`(${loaderMessage})`}</Typography>
                            </Box>
                        }
                    </Box>
                </HeaderFooterLayout>
            </StyleWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const StyleWrapper = styled(Box)({
    "& .mainContainer": {
        width: "100%",
        height: "100vh",
        background: "#C4C4C4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .loaderText": {
        fontSize: "14px",
        fontFamily: "Poppins",
    }
});
// Customizable Area End
