// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WishlistControllerWeb, {
  Props,
} from "./WishlistControllerWeb";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { styled } from "@mui/system";
import DeleteIcon from '@mui/icons-material/Delete';
import { AiOutlineClose } from "react-icons/ai";

export default class Wishlistweb extends WishlistControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {
      clickedProductId,
      productCardLoader,
      wishlistAllArray,
      isLoading,
      openDeleteConfirmationModal,
      openAddToCartSuccessModal,
      cartProduct,
    } = this.state;
    return (
      <StyleWrapper>
        <HeaderFooterLayout navigation={this.props.navigation}>
          <Box position={"relative"} overflow={"hidden"}>
            <Box className="breadcrumbBox">
              <Typography className="breadcrumbItem">Home</Typography>
              <Typography className="breadcrumbItem"><ChevronRightIcon className="breadcrumbArrow" /></Typography>
              <Typography className="breadcrumbItem">Profile</Typography>
              <Typography className="breadcrumbItem"><ChevronRightIcon className="breadcrumbArrow" /></Typography>
              <Typography className="breadcrumbItem">Wishlist</Typography>
            </Box>
            <Box padding={"0px 5% 5% 5%"}>
              {wishlistAllArray.length > 0 ?
                <Grid container spacing={3} padding={"0px"}>
                  {wishlistAllArray.map((item, index) => {
                    const product = item.attributes.item.data.attributes;
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ flexGrow: 1, borderRadius: '12px', overflow: 'hidden' }}>
                        <Card
                          data-test-id={`productCard${index}`}
                          onClick={() => this.openProductSummary(product.id)}
                          key={index}
                          className="productCard"
                          sx={{ boxShadow: 3, marginBottom: "10px", borderRadius: "12px", overflow: "hidden", position: "relative" }}
                        >
                          {clickedProductId === product.id && productCardLoader &&
                            <Box className="productCardLoader">
                              <CircularProgress value={10} size={60} />
                            </Box>
                          }
                          <CardActionArea>
                            <img className='cardsImg' src={this.getWishlistProductImage(product.media_files)} alt={'main image'} style={{ width: '100%', height: '450px', objectFit: 'cover' }} />
                            <CardContent>
                              <Box className="productTitleRatingBox" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography className="productTitle productTitleEllipsis" variant="h6">
                                  {product.name}
                                </Typography>
                                <Typography className="productPriceText" variant="h6">
                                  ₹ {product.price}
                                </Typography>
                              </Box>
                            </CardContent>
                            <CardActions style={{ padding: '0px 15px 30px 15px' }}>
                              <Button data-test-id={`addToCartButton${index}`} onClick={(event) => this.handleAddToCart(event, product.id)} className="addToCartButton">Add to Cart</Button>
                              <IconButton data-test-id={`deleteFromWishlistButton${index}`} onClick={(event) => this.handleOpenDeleteConfirmationModal(event, item.attributes.id)} style={{ color: "#A8A29E", width: "16px", marginLeft: "20px" }}><DeleteIcon /></IconButton>
                            </CardActions>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
                :
                <Box width={"100%"} height={"50vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                  <Typography style={{ color: "#A8A29E" }}>Your wishlist is empty!</Typography>
                </Box>
              }
            </Box>
            {isLoading &&
              <Box sx={{ background: "#80808080", overflow: "hidden" }} padding={"0px 5% 5% 5%"} position={"absolute"} top={"0px"} left={"0px"} width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress value={10} size={60} />
              </Box>
            }
          </Box>

          <Modal
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={openDeleteConfirmationModal}
            onClose={this.handleCloseDeleteConfirmationModal}
            aria-labelledby="delete-confirmation-modal"
            aria-describedby="delete-confirmation-modal"
          >
            <Box style={modalStyle.mainContainer}>
              <Box style={modalStyle.header}>
                <Typography datat-test-id="modalCloseButton" style={{ ...modalStyle.applySizeChartTitle, ...{ cursor: 'pointer' } }} onClick={() => this.handleCloseDeleteConfirmationModal()}><AiOutlineClose /> </Typography>
              </Box>
              <Box width={"100%"} height={"500px"} overflow={"auto"}>
                <Grid container padding={"24px"}>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Typography style={modalStyle.confirmationText}>Are you sure you would like to remove this item from the wishlist?</Typography>
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"} marginTop={"70px"} gap={"20px"}>
                    <Button style={modalStyle.cancelButton} data-test-id={`modalCancelButton`} onClick={this.handleCloseDeleteConfirmationModal}>Cancel</Button>
                    <Button style={modalStyle.removeButton} data-test-id={`modalRemoveButton`} onClick={() => this.handleDeleteFromWishlist()}>Remove</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>

          <Modal
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            open={openAddToCartSuccessModal}
            onClose={this.handleCloseAddToCartSuccessModal}
            aria-labelledby="add-to-cart-su-modal"
            aria-describedby="delete-confirmation-modal"
          >
            <Box style={modalStyle.cartModalMainContainer}>
              <Box style={modalStyle.cartSuccessHeader}>
                <Typography style={modalStyle.cartSuccessTitle}>Successfully added to your cart</Typography>
                <Typography datat-test-id="modalCloseButton2" style={{ ...modalStyle.applySizeChartTitle, ...{ cursor: 'pointer' } }} onClick={() => this.handleCloseAddToCartSuccessModal()}><AiOutlineClose /> </Typography>
              </Box>
              <Box width={"100%"} height={"500px"} overflow={"auto"}>
                {cartProduct &&
                  <Grid container padding={"24px"}>
                    <Grid item xs={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <img style={{ width: "100%", height: "150px", objectFit: "cover", objectPosition: "top" }} src={this.getWishlistProductImage(cartProduct.media_files)} alt="" />
                    </Grid>
                    <Grid item xs={8} display={"flex"} flexDirection={"column"} rowGap={"15px"} justifyContent={"start"} alignItems={"start"} paddingLeft={"20px"}>
                      <Typography style={modalStyle.productTitle}>{cartProduct.name}</Typography>
                      <Typography style={modalStyle.productPrice}> ₹ {cartProduct.price}</Typography>
                      <Typography style={modalStyle.productQuantity}>Qty: 1</Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"} marginTop={"70px"} gap={"20px"} paddingBottom={"20px"}>
                      <Button style={modalStyle.removeButton} data-test-id={`continueShoppingButton`} onClick={this.handleCloseAddToCartSuccessModal}>Continue Shopping</Button>
                      <Button style={modalStyle.cancelButton} data-test-id={`viewCartButton`} onClick={this.handleGotoCart}>View Cart</Button>
                    </Grid>
                  </Grid>
                }
              </Box>
            </Box>
          </Modal>
        </HeaderFooterLayout>
      </StyleWrapper>
    );
  }
}

const modalStyle = {
  mainContainer: {
    background: '#FFF',
    width: '450px',
    height: '300px',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  cartModalMainContainer: {
    background: '#FFF',
    width: '500px',
    height: '400px',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  header: {
    height: "max-content",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: "24px",
  },
  cartSuccessHeader: {
    height: "max-content",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    borderBottom: "1px solid #D6D3D1",
  },
  applySizeChartTitle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 600,
    color: '#292524',
  },
  cancelButton: {
    minWidth: "max-content",
    width: "50%",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 20px",
    borderRadius: "30px",
    cursor: "pointer",
  } as const,
  removeButton: {
    minWidth: "max-content",
    width: "50%",
    color: "#7F1187",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#FFFFFF",
    padding: "10px 20px",
    borderRadius: "30px",
    cursor: "pointer",
    border: "1px solid #7F1187",
  } as const,
  confirmationText: {
    color: "#57534E",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "23px",
  },
  cartSuccessTitle: {
    color: "#059669",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "28px",
  },
  productTitle: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
  },
  productPrice: {
    color: "#801188",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  productQuantity: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "22px",
  },
}

const StyleWrapper = styled(Box)({
  "& .breadcrumbBox": {
    width: "100%",
    padding: "5% 5% 4% 5%",
    display: 'flex',
    justifyContent: "start",
    flexDirection: "row",
    alignContent: "start",
    alignItems: "center"
  },
  "& .breadcrumbItem": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "#78716C",
  },
  "& .breadcrumbArrow": {
    width: "18px",
    padding: "0px 5px"
  },
  '& .productCard': {
    position: 'relative',
    transition: 'opacity 0.3s ease',
    cursor: 'pointer',
    borderRadius: '12px',
    marginBottom: '10px',
  },
  '& .cardsImg': {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  '& .productTitleRatingBox': {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    rowGap: "15px",
    justifyContent: "start",
  },
  '& .productTitle': {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: '1rem' },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .productPriceText': {
    fontWeight: 600,
    fontSize: "16px",
    color: "#801188",
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: "14px" },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .productSectionTitle': {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "24px",
    color: "#44403C",
    fontFamily: 'Poppins',
    marginTop: "30px",
    marginBottom: "30px",
  },
  "& .addToCartButton": {
    minWidth: "max-content",
    width: "30%",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 20px",
    borderRadius: "30px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    },
    "&:disabled": {
      background: "#808080",
      color: "#ababab",
    }
  },
  '& .productCardLoader': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: '#CDCDCDCC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
    top: '0px',
  },
});
// Customizable Area End
