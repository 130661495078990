import React from "react";
// Customizable Area Start
import { Box, Typography, InputLabel, Button, styled, Grid, TextField, MenuItem, Select } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import Loader from "../../../components/src/Loader";
// Customizable Area End

import UserBioController, {
  Props,
} from "./UserBioController";

export default class UserBio extends UserBioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userBioForm = () => {
    const {
      fullName,
      fullNameError,
      fullNameMsg,
      gender,
      language,
      dobMonth,
      dobDay,
      dobYear,
      formAddress,
      formCity,
      formState,
      formCountry,
      formPincode,
      addressError,
      cityError,
      stateError,
      countryError,
      pincodeError,
      addressErrorMsg,
      cityErrorMsg,
      stateErrorMsg,
      countryErrorMsg,
      pincodeErrorMsg,
      countries,
      states,
      cities,
      genders,
      languages,
      genderError,
      genderMsg,
      languageError,
      languageMsg,
      dobDayError,
      dobMonthError,
      dobYearError,
      days,
      months,
      years,
      isEditing,
      user,
      formattedDOB,
      isLoading,
    } = this.state;
    return (
      <StyleWrapper>
        <Loader loading={isLoading} />
        {user &&
          <>
            <Box display={"flex"} justifyContent={"space-between"} marginBottom={"40px"}>
              <Box>
                <Typography className="welcomeTitle">Welcome, {user.full_name}</Typography>
                <Typography className="dobText">{formattedDOB}</Typography>
              </Box>
              <Box>
                <Button className="editContainer" onClick={this.editUserInfo}>
                  <Typography className="editText">Edit</Typography>
                  <CreateIcon style={{ color: '#801187' }} />
                </Button>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"start"}>
              <Box>
                <img className="userImage" src={user.profile_picture || "https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"} alt="Profile image" />
              </Box>
              <Box>
                <Typography className="userFullName">{user.full_name}</Typography>
                <Typography className="dobText">{user.email}</Typography>
              </Box>
            </Box>
          </>
        }
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Grid container spacing={3} marginTop={"20px"} width={"100%"}>
              <Grid item xs={12}>
                <InputLabel>Full Name <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  name="fullName"
                  placeholder="Enter Full Name"
                  data-test-id="full-name-input"
                  variant="outlined"
                  value={fullName}
                  onChange={this.handleChange}
                  error={fullNameError}
                  helperText={fullNameMsg}
                  disabled={!isEditing}
                />
                {fullNameError && <Typography className="dropdownErrorMsg">{fullNameMsg}</Typography>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel id="country-dropdown-label">Gender <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <Select
                  data-test-id="country-dropdown"
                  labelId="country-dropdown-label"
                  id="countries-dropdown"
                  name="gender"
                  value={gender}
                  placeholder="Select Gender"
                  onChange={this.handleInputChange}
                  error={genderError}
                  style={{ width: "100%" }}
                  renderValue={(selected) => this.renderStringValueDropdown(selected, genders) || 'Select Gender'}
                  disabled={!isEditing}
                >
                  <MenuItem value="">Select Gender</MenuItem>
                  {genders.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                {genderError && <Typography className="dropdownErrorMsg">{genderMsg}</Typography>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputLabel>Language <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <Select
                  data-test-id="language-dropdown"
                  labelId="language-dropdown-label"
                  id="language-dropdown"
                  name="language"
                  value={language}
                  placeholder="Select Language"
                  onChange={this.handleInputChange}
                  error={countryError}
                  style={{ width: "100%" }}
                  renderValue={(selected) => this.renderStringValueDropdown(selected, languages) || 'Select Language'}
                  disabled={!isEditing}
                >
                  <MenuItem value="">Select Language</MenuItem>
                  {languages.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                {languageError && <Typography className="dropdownErrorMsg">{languageMsg}</Typography>}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Date of Birth <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <Box display={"flex"} flexDirection={"row"} columnGap={"15px"}>
                  <Select
                    data-test-id="month-dropdown"
                    labelId="month-dropdown-label"
                    id="month-dropdown"
                    name="dobMonth"
                    value={dobMonth}
                    placeholder="Select Month"
                    onChange={this.handleInputChange}
                    error={dobMonthError}
                    style={{ width: "100%" }}
                    renderValue={(selected) => this.renderStringValueDropdown(selected, months) || 'Select Month'}
                    disabled={!isEditing}
                  >
                    <MenuItem value="">Select Month</MenuItem>
                    {months.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  <Select
                    data-test-id="day-dropdown"
                    labelId="day-dropdown-label"
                    id="day-dropdown"
                    name="dobDay"
                    value={dobDay}
                    placeholder="Select Day"
                    onChange={this.handleInputChange}
                    error={dobDayError}
                    style={{ width: "100%" }}
                    renderValue={(selected) => this.renderStringValueDropdown(selected, days) || 'Select Day'}
                    disabled={!isEditing}
                  >
                    <MenuItem value="">Select Day</MenuItem>
                    {days.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  <Select
                    data-test-id="year-dropdown"
                    labelId="year-dropdown-label"
                    id="year-dropdown"
                    name="dobYear"
                    value={dobYear}
                    placeholder="Select Year"
                    onChange={this.handleInputChange}
                    error={dobYearError}
                    style={{ width: "100%" }}
                    renderValue={(selected) => this.renderStringValueDropdown(selected, years) || 'Select Year'}
                    disabled={!isEditing}
                  >
                    <MenuItem value="">Select Year</MenuItem>
                    {years.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </Box>
                {countryError && <Typography className="dropdownErrorMsg">{countryErrorMsg}</Typography>}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Address <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  name="formAddress"
                  placeholder="Enter Address"
                  data-test-id="address-input"
                  variant="outlined"
                  value={formAddress}
                  onChange={this.handleChange}
                  error={addressError}
                  helperText={addressErrorMsg}
                  disabled={!isEditing}
                />
                {addressError && <Typography className="dropdownErrorMsg">{addressErrorMsg}</Typography>}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="country-dropdown-label">Country <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <Select
                  data-test-id="country-dropdown"
                  labelId="country-dropdown-label"
                  id="countries-dropdown"
                  name="formCountry"
                  value={formCountry}
                  placeholder="Select Country"
                  onChange={this.handleInputChange}
                  error={countryError}
                  style={{ width: "100%" }}
                  renderValue={(selected) => this.renderStringValueDropdown(selected, countries) || 'Select Country'}
                  disabled={!isEditing}
                >
                  <MenuItem value="">Select Country</MenuItem>
                  {countries.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                {countryError && <Typography className="dropdownErrorMsg">{countryErrorMsg}</Typography>}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <InputLabel id="state-dropdown-label">State <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <Select
                  data-test-id="state-dropdown"
                  labelId="state-dropdown-label"
                  id="states-dropdown"
                  name="formState"
                  value={formState}
                  placeholder="Select State"
                  onChange={this.handleInputChange}
                  error={stateError}
                  style={{ width: "100%" }}
                  renderValue={(selected) => this.renderStringValueDropdown(selected, states) || 'Select State'}
                  disabled={!isEditing}
                >
                  <MenuItem value="">Select State</MenuItem>
                  {states.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                {stateError && <Typography className="dropdownErrorMsg">{stateErrorMsg}</Typography>}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <InputLabel>City <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <Select
                  data-test-id="city-dropdown"
                  labelId="city-dropdown-label"
                  id="city-dropdown"
                  name="formCity"
                  value={formCity}
                  placeholder="Select City"
                  onChange={this.handleInputChange}
                  error={cityError}
                  style={{ width: "100%" }}
                  renderValue={(selected) => this.renderStringValueDropdown(selected, cities) || 'Select City'}
                  disabled={!isEditing}
                >
                  <MenuItem value="">Select City</MenuItem>
                  {cities.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
                {cityError && <Typography className="dropdownErrorMsg">{cityErrorMsg}</Typography>}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <InputLabel>Pin Code <Typography className="required" component={"span"}>*</Typography></InputLabel>
                <TextField
                  style={{ width: "100%" }}
                  name="formPincode"
                  placeholder="Enter pin code"
                  data-test-id="pincode-input"
                  variant="outlined"
                  value={formPincode}
                  onChange={this.handleInputChange}
                  error={pincodeError}
                  helperText={pincodeErrorMsg}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={!isEditing} data-test-id="saveButton" className="saveButton" type="button" onClick={this.saveUserData}>
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyleWrapper>
    );
  }
  // Customizable Area End

  render() {
    const { role } = this.state;

    return (
      // Customizable Area Start
      <>
        {role === 'vendor' &&
          <HeaderVendorFooter navigation={this.props.navigation}>
            {this.userBioForm()}
          </HeaderVendorFooter>
        }
        {(role === 'user' || !role) &&
          <HeaderFooterLayout navigation={this.props.navigation}>
            {this.userBioForm()}
          </HeaderFooterLayout>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleWrapper = styled(Box)({
  marginBottom: "16px",
  margin: "4%",
  padding: "2%",
  border: "1px solid #D6D3D1",
  borderRadius: "12px",

  "& .common-form-label": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "4px",
    display: "block",
    "& span": {
      color: "#DC2626",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .MuiInputBase-root.Mui-error": {
    borderRadius: "8px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "0px",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiFormHelperText-root": {
    color: "#DC2626",
    margin: "8px 0 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171 !important",
    borderWidth: "1px !important",
    borderStyle: "solid",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderWidth: "1px",
  },
  "& .welcomeTitle": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#1C1917",
  },
  "& .dobText": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#78716C",
  },
  "& .editText": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#801187",
    marginRight: "10px",
    textTransform: "capitalize",
  },
  "& .userImage": {
    width: "56px",
    height: "56px",
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: "15px",
  },
  "& .userFullName": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#1C1917",
    marginBottom: "10px",
  },
  '& .required': {
    color: '#DC2626',
    marginLeft: '5px',
  },
  "& .saveButton": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "175px",
    cursor: "pointer",
    marginTop: "15px",
    "&:hover": {
      background: "#7F1287"
    },
    "&:disabled": {
      background: "#F1F5F9",
      color: "#94A3B8",
    }
  },
});
