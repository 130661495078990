import React from "react";
// Customizable Area Start

import { Box, Typography, InputLabel, Button, InputAdornment, styled, Grid, IconButton } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

export type touched = boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
export type errors = string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined
import * as Yup from "yup";
import { profile, showPassword, hidePassword } from "./assets";

// Customizable Area End

import UserProfileController, {
  Props,
} from "./UserProfileController";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import { View } from "@builder/component-library";
import TextInputFiled from "../../../../packages/components/src/TextInputField.web";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import SelectField from "../../../../packages/components/src/SelectField.web";
import HeaderFooterLayout from "../../../../packages/components/src/HeaderFooterLayout";
import OtpInput from "../../../../packages/components/src/OtpInput";

export default class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  helperText = (
    touched: touched,
    errors: errors,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }
  isError = (
    touched: touched,
    errors: errors,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

  handleErrorText = (touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined, errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,) => {
    return touched && errors
  }

  showPasswordField = (values: any, handleChange: any, handleBlur: any, setFieldValue: any, touched: FormikTouched<any>, errors: any) => {
    return (
      <>
        <InputLabel className="labelClass">Current Password</InputLabel>
        <TextInputFiled
          data-test-id="password-input"
          sx={{ width: { xs: "100%", sm: "77vw", lg: "42vw", md: "77vw" }, paddingTop: "1.5rem" }}
          name="currentPassword"
          placeholder="Enter your password"
          value={values.currentPassword}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={values.showPassword ? "text" : "password"}
          error={this.isError(touched.currentPassword, errors.currentPassword)}
          helperText={this.getHelperText(touched?.currentPassword, errors?.currentPassword)}
          disabled={!this.state.isEditing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="icon-toggle"
                  onClick={this.handleClick.bind(this, setFieldValue, values)
                  }
                  edge="end"
                >
                  {values.showPassword ? (
                    <img
                      src={showPassword}
                      alt="show"
                      className="password-images"
                    />
                  ) : (
                    <img
                      src={hidePassword}
                      alt="hide"
                      className="password-images"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InputLabel className="labelClass">New Password</InputLabel>
        <TextInputFiled
          data-test-id="password-input"
          sx={{ width: { xs: "100%", sm: "77vw", lg: "42vw", md: "77vw" }, paddingTop: "1.5rem" }}
          name="newPassword"
          placeholder="Enter your password"
          value={values.newPassword}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={values.showNewPassword ? "text" : "password"}
          error={this.isError(touched.newPassword, errors.newPassword)}
          helperText={this.getHelperText(touched?.newPassword, errors?.newPassword)}
          disabled={!this.state.isEditing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-test-id="icon-toggle"
                  onClick={this.handleClickNewPassword.bind(this, setFieldValue, values)
                  }
                  edge="end"
                >
                  {values.showNewPassword ? (
                    <img
                      src={showPassword}
                      alt="show"
                      className="password-images"
                    />
                  ) : (
                    <img
                      src={hidePassword}
                      alt="hide"
                      className="password-images"
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </>
    )
  }

  showContactNumberField = (values: any, handleChange: any, handleBlur: any, setFieldValue: any, touched: FormikTouched<any>, errors: any) => {
    return (
      <>
        <Box className="numberField" sx={{ display: "flex", gap: "1.4rem", flexDirection: { xs: 'column', sm: 'row' }, alignItems: "center" }}>
          <SelectField sx={{
            width: "95px",
            "& .select-dropdown__menu": {
              width: "max-content"
            }
          }}
            placeholder="+91"
            label=""
            name="phoneId"
            options={this.state.phoneNumberData.map((data: any) => ({
              label: data.label,
              value: data.value,
            }))}
            isDisabled={!this.state.isEditing}
            setFieldValue={setFieldValue} value={values?.phoneId} helperText={undefined} handleBlur={handleBlur} />
          <TextInputFiled
            sx={{ width: { xs: "100%", sm: "64vw", lg: "33.5vw", md: "65.5vw" } }}
            name="contactNumber"
            data-test-id="full-name-input"
            handleChange={handleChange}
            value={values.contactNumber}
            handleBlur={handleBlur}
            placeholder="0000000000"

            disabled={!this.state.isEditing}
            error={this.isError(touched?.contactNumber, errors?.contactNumber)}
            type="tel"
            helperText={this.getHelperText(touched?.contactNumber, errors?.contactNumber)}
            InputProps={{
              endAdornment: this.state.isEditing ? (
                <Button
                  sx={{ minWidth: "auto", padding: 0, fontSize: "16px", fontFamily: "poppins", fontWeight: 400, color: "#7F1187", textTransform: "none" }}
                  onClick={this.handleShowOtpInput}
                >
                  Verify
                </Button>
              ) : null
            }}
          />
        </Box>
        {this.state.showOtpInput &&
          <Box>
            <OtpInput onChange={(otp: any) => console.log('Entered OTP:', otp)} />
          </Box>
        }
      </>
    )
  }

  getHelperText = (isTouched: any, errors: any) => (isTouched && errors ? errors : "");

  showSubmitButton = () => {
    return (
      <>
        <Button className="saveButton2" type="submit" >
          Save changes
        </Button>
      </>
    )
  }
  isFormikValueSet = false

  profileBoxMain = () => {
    return (
      <View style={{ display: "flex", alignItems: "center" }}>
        <StyledComponents style={{ width: "90vw" }}>
          <Box className="profileBox">
            <Typography className="profileTitle">{'Edit User Information'}</Typography>
            <Box className="profileContent">
              <Box className="avatarContainer">
                <Box className="imageBox"
                  sx={{
                    backgroundImage: `url(${this.state.formData.profile || profile})`,
                    position: 'relative',
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  {!this.state.selectedImage && (
                    <Box
                      component="span"
                      style={{
                        position: 'absolute',
                        height: '150px',
                        width: '150px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        backgroundImage: `url(${this.state.formData.profile || profile})`,
                        backgroundSize: 'cover',
                      }}
                    >
                    </Box>
                  )}
                  {this.state.isEditing && this.state.isEditingImage && (
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <CameraAltOutlinedIcon className="iconOverlay" />
                      <Typography
                        className="textOverlay"
                        variant="h6"
                        style={{ color: 'white', marginTop: '10px' }}
                      >
                        Edit Picture
                      </Typography>
                      <input
                        data-test-id="filechange"
                        type="file"
                        accept="image/*"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          cursor: 'pointer',
                        }}
                        onChange={this.handleFileChange}
                        disabled={!this.state.isEditing}
                      />
                    </Box>
                  )}
                </Box>
                <Typography className="businessName">{this.state.formData.fullName}</Typography>
              </Box>
              {!this.state.isEditing && (
                <Button className="editContainer" onClick={this.handleEdit}>
                  <Typography className="editText">Edit</Typography>
                  <CreateIcon style={{ color: '#801187' }} />
                </Button>
              )}
            </Box>
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={this.submitFormData}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                if (this.state.showData && !this.isFormikValueSet) {
                  this.isFormikValueSet = true
                  setFieldValue("fullName", this.state.formData.fullName)
                  setFieldValue("email", this.state.formData.email)
                  setFieldValue("contactNumber", this.state.formData.contactNumber)
                  setFieldValue("phoneId", this.state.formData.phoneId)
                }
                return (
                  <Form onSubmit={handleSubmit} noValidate className="formContainer">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                        <Typography className="formTitle">Basic Information:</Typography>
                        <Grid container sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                          <Grid item xs={12} sm={12} lg={6} md={6}>
                            <InputLabel className="labelClass">
                              Full Name <span className="required">*</span>
                            </InputLabel>
                            <TextInputFiled
                              data-test-id="change"
                              sx={{ width: { xs: "84vw", sm: "35vw", lg: "20vw" } }}
                              name="fullName"
                              placeholder="Enter your full name"
                              value={values.fullName}
                              handleChange={handleChange}
                              disabled={!this.state.isEditing}
                              error={this.isError(touched?.fullName, errors?.fullName)}
                              handleBlur={handleBlur}
                              type="text"
                              helperText={this.getHelperText(touched?.fullName, errors?.fullName)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} lg={6} md={6}>
                            <InputLabel className="labelClass">
                              Email <span className="required">*</span>
                            </InputLabel>
                            <TextInputFiled
                              data-test-id="change"
                              sx={{ width: { xs: "84vw", sm: "35vw", lg: "20vw" } }}
                              name="email"
                              placeholder="Enter your email"
                              handleBlur={handleBlur}
                              value={values.email}
                              handleChange={handleChange}
                              error={this.isError(touched?.email, errors?.email)}
                              type="text"
                              disabled={!this.state.isEditing}
                              helperText={this.getHelperText(touched?.email, errors?.email)}
                            />
                          </Grid>
                        </Grid>
                        <InputLabel className="labelClass">Contact Number</InputLabel>
                        {this.showContactNumberField(values, handleChange, handleBlur, setFieldValue, touched, errors)}
                        {this.state.formData.accountType.toLocaleLowerCase() === 'emailaccount' &&
                          <Typography className="formTitle">Password</Typography>
                        }
                        {(!this.state.isEditing && this.state.formData.accountType.toLocaleLowerCase() === 'emailaccount') && (
                          <>
                            <InputLabel className="labelClass">Current Password</InputLabel>
                            <TextInputFiled
                              data-test-id="password-input"
                              sx={{ width: { xs: "100%", sm: "77vw", lg: "42vw", md: "77vw" }, paddingTop: "1.5rem" }}
                              name="currentPassword"
                              placeholder="Enter your password"
                              value={values.currentPassword}
                              handleBlur={handleBlur}
                              error={this.isError(touched.currentPassword, errors.currentPassword)}
                              type={values.showPassword ? "text" : "password"}
                              helperText={undefined}
                              disabled={!this.state.isEditing}
                              handleChange={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      disabled
                                      aria-label="toggle password visibility"
                                      data-test-id="icon-toggle"
                                      onClick={this.handleClick.bind(this, setFieldValue, values)
                                      }
                                      edge="end"
                                    >
                                      {values.showPassword ? (
                                        <img
                                          src={showPassword}
                                          alt="show"
                                          className="password-images"
                                        />
                                      ) : (
                                        <img
                                          src={hidePassword}
                                          alt="hide"
                                          className="password-images"
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </>
                        )}
                        {(this.state.isEditing && this.state.formData.accountType.toLocaleLowerCase() === "emailaccount") && (
                          <>
                            {this.showPasswordField(values, handleChange, handleBlur, setFieldValue, touched, errors)}
                          </>
                        )}
                        {!this.state.isEditing ? (
                          <Button className="savebutton1" disabled>
                            Save changes
                          </Button>
                        ) : (
                          <>
                            {this.showSubmitButton()}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>

                      </Grid>
                    </Grid>
                  </Form>
                )
              }

              }
            </Formik>
          </Box>
        </StyledComponents>
      </View>
    )
  }
  // Customizable Area End

  render() {
    const { role } = this.state;
    return (
      // Customizable Area Start
      <>
        {role === 'vendor' &&
          <HeaderVendorFooter navigation={this.props.navigation}>
            {this.profileBoxMain()}
          </HeaderVendorFooter>
        }
        {(role === 'user' || !role) &&
          <HeaderFooterLayout navigation={this.props.navigation}>
            {this.profileBoxMain()}
          </HeaderFooterLayout>
        }

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const accountType = localStorage.getItem("accountType")
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Full Name is required')
    .min(2, 'Enter a valid Full Name')
    .max(50, 'Enter a valid Full Name')
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)*$/,
      'Full Name should only contain letters'
    ),
  email: Yup.string()
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9]+@[A-Za-z]+\.[cC][oO][mM]$/,
      'Please enter valid email address'
    ),
  contactNumber: Yup.string()
    .required('Contact Number is required')
    .min(8, " Enter a valid Contact Number")
    .max(10, ' Enter a valid Contact Number')
    .matches(/^\d+$/,
      'Enter a valid Contact Number')
    .nullable()
  ,
  currentPassword: Yup.string().when([], {
    is: () => accountType?.toLocaleLowerCase() === 'emailaccount',
    then: Yup.string()
      .min(8, 'Enter a valid Current Password')
      .max(12, 'Enter a valid Current Password')
      .matches(/[A-Z]/, 'Current Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Current Password must contain at least one lowercase letter')
      .matches(/\d/, 'Current Password must contain at least one number')
      .matches(/[\W_]/, 'Current Password must contain at least one special character'),
    otherwise: Yup.string().notRequired(),
  }),
  newPassword: Yup.string().when([], {
    is: () => accountType?.toLocaleLowerCase() === 'emailaccount',
    then: Yup.string()
      .min(8, 'Enter a valid New Password')
      .max(12, 'Enter a valid New Password')
      .matches(/[A-Z]/, 'New Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'New Password must contain at least one lowercase letter')
      .matches(/\d/, 'New Password must contain at least one number')
      .matches(/[\W_]/, 'New Password must contain at least one special character'),
  }),
}
);

const StyledComponents = styled(Box)({
  '& .formContainer': {
    paddingTop: "2rem",
  },
  '& .profileBox': {
    width: '90vw',
    border: '1px solid #D6D3D1',
    height: 'auto',
    borderRadius: '8px',
    padding: '2rem',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      width: '90vw',
      padding: '1rem',
    },
    '@media (max-width: 480px)': {
      width: '95vw',
      padding: '0.5rem',
    },
  },
  '& .profileTitle': {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'poppins',
    paddingBottom: '1rem',
  },
  '& .profileContent': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  '& .required': {
    color: "red"
  },
  '& .imageBox': {
    position: 'relative',
    width: 150,
    height: 150,
    border: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '@media (max-width: 480px)': {
      width: 120,
      height: 120,
    },
  },
  '& .textOverlay': {
    position: 'absolute',
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
    fontFamily: 'poppins',
    fontWeight: 700,
  },
  '& .iconOverlay': {
    position: 'absolute',
    color: 'white',
    fontSize: '40px',
    top: '20%',
    width: '20px',
    height: '18px',
    paddingTop: '1rem',
  },
  '& .avatarContainer': {
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  '& .businessName': {
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'poppins',
  },
  '& .editContainer': {
    display: 'flex',
    gap: '1.5rem',
    alignItems: 'center',
    textTransform: "none"
    , '@media (max-width: 768px)': {
      paddingTop: '1rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  '& .editText': {
    fontFamily: 'poppins',
    fontSize: '16px',
    fontWeight: 600,
    color: '#801187',
  },
  '& .formTitle': {
    fontSize: '16px',
    fontFamily: 'poppins',
    fontWeight: 700,
    paddingBottom: '1rem',
  },
  '& .labelClass': {
    fontSize: '14px',
    fontWeight: 500,
    color: '#334155',
    fontFamily: 'poppins',
  },
  '& .saveButton2': {
    width: '172px',
    height: '44px',
    borderRadius: '33px',
    background: '#801187',
    color: 'white',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'poppins',
    marginTop: '2rem',
    '@media (max-width: 480px)': {
      width: '100%',
      height: '36px',
    },
    '&:hover': {
      background: '#801187',
      color: 'white',
    },
  },
  '& .savebutton1': {
    width: '172px',
    height: '44px',
    borderRadius: '33px',
    background: '#F1F5F9',
    color: '#64748B',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'poppins',
    marginTop: '2rem',
    '@media (max-width: 480px)': {
      width: '100%',
      height: '36px',
    },
  },
  '& .inputContainer': {
    display: 'flex',
    gap: '2rem',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  '& .numberContainer': {
    display: 'flex',
    gap: '1.4rem',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
});
