import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Rating, Button, Grid, Card, LinearProgress, InputLabel, TextField, CircularProgress } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
// Customizable Area End

import ReviewsController, { Props } from "./ReviewsController";
import moment from "moment";

export default class Reviews extends ReviewsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const ratingDetails = [
            {
                id: 1,
                rating: 10,
                percentage: 30,
            },
            {
                id: 2,
                rating: 9,
                percentage: 40,
            },
            {
                id: 3,
                rating: 8,
                percentage: 30,
            },
            {
                id: 4,
                rating: 7,
                percentage: 40,
            },
            {
                id: 5,
                rating: 6,
                percentage: 30,
            },
            {
                id: 6,
                rating: 5,
                percentage: 28,
            },
            {
                id: 7,
                rating: 4,
                percentage: 30,
            },
            {
                id: 8,
                rating: 3,
                percentage: 5,
            },
            {
                id: 9,
                rating: 2,
                percentage: 15,
            },
            {
                id: 10,
                rating: 1,
                percentage: 10,
            }
        ]

        const {
            reviewComment,
            reviewCommentError,
            reviewCommentMsg,
            reviewRating,
            reviewRatingError,
            reviewRatingMsg,
            productReviews,
            isLoading,
        } = this.state;

        const { productName } = this.props;

        return (
            // Customizable Area Start
            <StyleWrapper>
                <Typography className="reviewTitle">Reviews for {productName}</Typography>
                <Grid container width={"100%"}>
                    <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                        <Box className="ratingMainBox" height={"45px"} display={"flex"}>
                            <StarIcon />
                            <Typography style={{ fontSize: "16px" }}> 7/10 </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={5} lg={5} md={15} sm={12} xs={12}>
                        {ratingDetails.map((item) => (
                            <Box key={item.id} display="flex" alignItems="center" gap={2} marginBottom={"10px"}>
                                <Box display="flex" alignItems="center" gap={0.5} width={"80px"}>
                                    <StarIcon style={{ color: "#F59E0B" }} />
                                    <Typography variant="body2">{item.rating}</Typography>
                                </Box>

                                <Box flex={1} mx={1}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={item.percentage}
                                        sx={{
                                            height: 12,
                                            borderRadius: 4,
                                            backgroundColor: '#e0e0e0',
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: '#F59E0B',
                                            },
                                        }}
                                    />
                                </Box>

                                <Typography variant="body2" color="text.secondary">
                                    {item.percentage}%
                                </Typography>
                            </Box>
                        ))
                        }
                    </Grid>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12} paddingLeft={"80px"}>
                        <Card
                            className="productCard"
                            sx={{ boxShadow: 3, marginBottom: "10px", borderRadius: "8px", overflow: "hidden", padding: "30px" }}
                        >
                            <CustomReviewForm>
                                <Grid container spacing={3} width={"100%"}>
                                    <Grid item xs={12}>
                                        <Typography className="reviewTitle">
                                            Write a Review
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel sx={{ marginBottom: "10px", color: "#000", fontSize: "16px" }}>Rate a vendor</InputLabel>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                            <Rating data-test-id="ratingStarChange" value={reviewRating} max={10} precision={1} onChange={this.handleRatingChange} size="medium" />
                                            <Typography className="outOfText">out of -/10</Typography>
                                        </Box>
                                        {reviewRatingError && <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>{reviewRatingMsg}</Typography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel sx={{ marginBottom: "10px", color: "#000", fontSize: "16px" }}>Comment</InputLabel>
                                        <TextField
                                            style={{ width: "100%" }}
                                            name="reviewComment"
                                            placeholder="Share about your experinece"
                                            data-test-id="commentInputChange"
                                            variant="outlined"
                                            value={reviewComment}
                                            onChange={this.handleCommitInputChange}
                                            error={reviewCommentError}
                                            helperText={reviewCommentMsg}
                                            multiline={true}
                                            rows={3}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isLoading ?
                                            <Box width={"100%"} textAlign={"center"}>
                                                <CircularProgress style={{ color: "#7F1287" }} size={30} />
                                            </Box>
                                            :
                                            <Button data-test-id="submitReviewButton" className="proceedBtn" type="button" onClick={this.handleSubmitReview}>
                                                Submit
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </CustomReviewForm>
                        </Card>
                    </Grid>
                </Grid>
                {productReviews.length > 0 ?
                    <Grid container width={"100%"} marginTop={"60px"}>
                        {productReviews.map((item) => (
                            <Grid item xs={12}>
                                <Box display={"flex"} flexDirection={"row"} columnGap={"15px"}>
                                    <Box width={"55px"} height={"55px"} borderRadius={"50%"} overflow={"hidden"} border={"2px solid #F0E5FF"}>
                                        <img style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                            src={item.user.profile_picture || "https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"} alt="avatar" />
                                    </Box>
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <Typography className="userName">{item.user.full_name}</Typography>
                                        <Typography className="reviewDate">Reviewed on {moment(item.date).format('DD MMMM YYYY')}</Typography>
                                    </Box>
                                    <Box className="ratingMainBox" height={"30px"} display={"flex"}>
                                        <StarIcon />
                                        <Typography style={{ fontSize: "14px" }}> 8/10 </Typography>
                                    </Box>
                                </Box>
                                <Box marginTop={"15px"} borderBottom={"1px solid #6F6F6F"} paddingBottom={"20px"} marginBottom={"50px"}>
                                    <Typography className="reviewContent">
                                        {item.comment}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Grid container width={"100%"} marginTop={"60px"}>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px", border: "1px solid #C4C4C4", borderRadius: "12px" }}>
                            <Typography style={{ color: "#78716C", fontWeight: 600, fontSize: "16px" }}>No reviews added for this product.</Typography>
                        </Grid>
                    </Grid>
                }
            </StyleWrapper >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CustomReviewForm = styled(Box)({
    marginBottom: "16px",
    "& .common-form-label": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#334155",
        marginBottom: "4px",
        display: "block",
        "& span": {
            color: "#DC2626",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "22px",
        },
    },
    "& .MuiInputBase-input": {
        padding: "10.5px 8px ",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        color: "#0F172A",
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#A8A29E",
        opacity: 1,
    },
    "& .MuiInputBase-root.Mui-error": {
        borderRadius: "8px",
    },
    "& .MuiInputBase-root": {
        borderRadius: "0px",
        backgroundColor: "#FFFFFF",
    },
    "& .MuiFormHelperText-root": {
        color: "#DC2626",
        margin: "8px 0 0",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171 !important",
        borderWidth: "1px !important",
        borderStyle: "solid",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderLeft: "0px",
        borderBottom: "1px",
        borderRight: "0px",
        borderTop: "0px",
        borderStyle: "solid",
        borderColor: "#E2E8F0",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        borderWidth: "1px",
    },
    "& .proceedBtn": {
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "155px",
        cursor: "pointer",
        "&:hover": {
            background: "#7F1287"
        },
        "&:disabled": {
            background: "#808080",
            color: "#ababab",
        }
    },
});

const StyleWrapper = styled(Box)({
    '& .reviewTitle': {
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "24px",
        textAlign: "left",
        color: "#292524",
        marginBottom: "20px",
    },
    '& .ratingMainBox': {
        minWidth: "80px",
        width: "max-content",
        borderRadius: "8px",
        background: "#059669",
        color: "#fff",
        fontFamily: "Poppins",
        display: "felx",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .userName": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#292524",
    },
    "& .reviewDate": {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#78716C",
    },
    "& .reviewContent": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "26px",
        color: "#57534E",
    },
    "& .outOfText": {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "34px",
        color: "#646464",
    }
});
// Customizable Area End
