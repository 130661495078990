Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.patchApiMethod = 'PATCH'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.deleteProductFromCartEndpoint = "bx_block_shopping_cart/order_items";
exports.getCartDataEndpoint = "bx_block_shopping_cart/orders/view_cart";
exports.userAddressEndpoint = "bx_block_address/addresses";
exports.getCouponsEndpoint = "bx_block_discount/checkout_addition_costs/fetch_global_coupons";
exports.applyCouponEndpoint = "bx_block_shopping_cart/orders/apply_coupon";
exports.getStatesEndpoint = "account_block/accounts/country_code_and_flags/fetch_states";
exports.getCitiesEndpoint = "account_block/accounts/country_code_and_flags/fetch_cities";
exports.addToCartEndpoint = "bx_block_shopping_cart/orders";
exports.productSuggestionEndpoint = "bx_block_recommendation/catalogue_recommends/recent_sold_product";
exports.shopByCategoryEndpoint = "bx_block_categories/categories/shop_by_category";
exports.createOrderEndpoint = "bx_block_razorpay/create_order";
exports.verifyPaymentSignature = "bx_block_razorpay/verify_payment_signature";
exports.razorOrderDetailsEndpoint = "bx_block_razorpay/order_details";
exports.getProductRecommendation="bx_block_recommendation/catalogue_recommends/recent_sold_product";
exports.addAddressToOrderEndpoint="bx_block_razorpay/add_address";
// Customizable Area End
