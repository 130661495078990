import React from "react";
import { styled, Box } from "@mui/material";
import HeaderVendor from "./HeaderVendor.web"
import Footer from "./Footer.web";
import { scrollToTop } from "./utils";
import { getStorageData } from "../../framework/src/Utilities";
import { useEffect } from "react";

const backgroundImage = require("./assets/background_image.png");

interface IHeaderVendorFooter {
  navigation: any;
  children: React.ReactNode;
  isFooterShow?: boolean;
}

const HeaderVendorFooter: React.FC<IHeaderVendorFooter> = ({
  navigation,
  children,
  isFooterShow = true,
}) => {

  useEffect(() => {
    (async () => {     
      const role = await getStorageData("role");
      const token = await getStorageData("authToken");
      if(!token){
        navigation.navigate("VendorLogin");
      }
      if(token && role && role.toLocaleLowerCase() === 'user'){
        navigation.navigate("LandingPage");
      }
    })();
    scrollToTop();
  }, []);

  return (
    <HeaderVendorFooterStyle data-test-id="InspirationStyle">
      <HeaderVendor className="header" navigation={navigation} isOtherPage />
      <Box className="body-data">
        {children}
        {isFooterShow && <Footer className="footer" />}
      </Box>
    </HeaderVendorFooterStyle>
  );
};

export default HeaderVendorFooter;

const HeaderVendorFooterStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  "& .header": {
    flex: "0 0 auto",
  },
  "& .body-data": {
    backgroundImage: `url(${backgroundImage})`,
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  "& .footer": {
    flex: "0 0 auto",
  },
}));
