import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { productDefaultImage } from "../../../blocks/sorting/src/assets";
// Customizable Area End

export const configJSON = require("./config");

interface MyCartData {
  status: string;
  total_mrp: number;
  total_items: number;
  total_tax: number;
  discount_on_mrp: number;
  total_amount: number;
  customer: Customer;
  address: Address;
  order_items: OrderItem[];
}

interface OrderData {
  data: {
    id: string;
    type: string;
    attributes: {
      status: string;
      total_mrp: number;
      total_items: number;
      total_tax: number;
      discount_on_mrp: number;
      total_amount: number;
      customer: Customer;
      address: Address;
      order_items: OrderItem[];
      coupon_details: {
        id: string,
        type: string,
        attributes: AppliedCouponData,
      }
    };
  };
  error: string;
}

interface AppliedCouponData {
  id: number,
  cost_type: string,
  coupon_code: string,
  exp_date: string,
  min_order_value: string,
  max_discount: string,
  discount_percentage: string,
  terms_policies: string,
  is_expired: boolean,
  amount: string,
}

interface Customer {
  id: string;
  type: string;
  attributes: CustomerAttributes;
}

interface CustomerAttributes {
  activated: boolean;
  country_code: string | null;
  full_name: string;
  email: string;
  first_name: string | null;
  full_phone_number: string;
  last_name: string | null;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  profile_picture: string | null;
  primary_contact_no: string | null;
  primary_contact_country_code: string | null;
  primary_contact_phone_number: string | null;
  business_name: string;
  secondary_contact_no: string | null;
  secondary_contact_country_code: string | null;
  secondary_contact_phone_number: string | null;
  GST_number: string;
  service_provided: Service;
  portfolio_link: string;
  business_detail_description: string;
  bank_name: string;
  account_number: string;
  ifsc_code: string;
  branch_name: string;
  social_media_links: string[];
  products_offered: string[];
  vendor_address: string | null;
  role_id: string;
}

interface Service {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface Address {
  data: {
    id: string,
    type: string,
    attributes: AddressAttributes
  }
}

interface AddressAttributes {
  latitude: string | null,
  longitude: string | null,
  address: string,
  address_type: string,
  country: string,
  city: string,
  state: string,
  pincode: string,
  service_locations: string[]
}

export interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

interface OrderItemAttributes {
  price: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  other_charges: any | null;
  total_price: number;
  discounted_price: number | null;
  location: any | null;
  product: Product;
  discount?: number;
}

interface Product {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

export interface ProductAttributes {
  id: number;
  name: string;
  SKU: string;
  category: Category;
  description: string;
  fabric_details: string;
  price: string;
  available_quantity: number;
  media_files: MediaFile[];
  functions: Function[];
  service_locations: {
    data: ServiceLocation[];
  };
  product_variants: ProductVariant;
  custom_fields: CustomFields;
  created_at: string;
  is_published: boolean;
  product_faqs: any[];
  size_chart: string;
  is_available: boolean;
  is_deleted: boolean;
  coupon: any | null;
}

interface Category {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string | null;
    dark_icon: string | null;
    dark_icon_active: string | null;
    dark_icon_inactive: string | null;
    light_icon: string | null;
    light_icon_active: string | null;
    light_icon_inactive: string | null;
    rank: number | null;
    created_at: string;
    updated_at: string;
    selected_sub_categories: any | null;
    image: string;
  };
}

interface MediaFile {
  url: string;
  filename: string;
  content_type: string;
  byte_size: number;
}

interface Function {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    function_image: string;
    description: string | null;
  };
}

interface ServiceLocation {
  id: string;
  type: string;
  attributes: {
    id: number;
    location_name: string;
  };
}

interface ProductVariant {
  id: string;
  type: string;
  attributes: {
    id: number;
    product_size: ProductSize;
    product_color: ProductColor;
    available_count: number;
  };
}

interface ProductSize {
  id: string;
  type: string;
  attributes: {
    id: number;
    size_label: string;
    measurement: number;
  };
}

interface ProductColor {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    color_image: string;
  };
}

interface CustomFields {
  color: string;
  material: string;
}

interface UserAddressData {
  id: string,
  type: string,
  attributes: {
    latitude: number,
    longitude: number,
    address: string,
    address_type: string,
    country: string,
    city: string,
    state: null,
    pincode: string,
  }
}

interface CouponsData {
  id: number,
  coupon_code: string,
  amount: string
}
interface DropdownData {
  id: number,
  label: string,
  value: string,
  code?: string,
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isLoading: boolean;
  id: number;
  myCartData: MyCartData | null;
  token: string;
  showAddress: string;
  quantity: number;
  cartOrderItems: OrderItem[];
  errorMessage: string;
  userAddressList: UserAddressData[];
  customerData: CustomerAttributes | null;
  selectedAddress: string;
  addNewAddress: boolean;
  formAddress: string;
  formCity: string;
  formState: string;
  formCountry: string;
  formPincode: string;
  formContactNo: string;
  formEmail: string;
  addressError: boolean;
  cityError: boolean;
  stateError: boolean;
  countryError: boolean;
  pincodeError: boolean;
  contactNoError: boolean;
  emailError: boolean;
  addressErrorMsg: string;
  cityErrorMsg: string;
  stateErrorMsg: string;
  countryErrorMsg: string;
  pincodeErrorMsg: string;
  contactNoErrorMsg: string;
  emailErrorMsg: string;
  editAddress: boolean;
  editAddressId: string;
  isCouponApplied: boolean;
  openAddCouponModal: boolean;
  couponsList: CouponsData[];
  selectedCoupon: number;
  appliedCoupon: AppliedCouponData | null;
  countries: DropdownData[];
  states: DropdownData[];
  cities: DropdownData[];
  productFunctions: DropdownData[];
  productCategories: DropdownData[];
  currentCartId: number;
  productRecommendationData: any[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShopMyCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInspirationDetailsCallId: string = "";
  getInspirationDetailsPhotoCallId: string = "";
  shopMyCartApiCallId: string = "";
  deleteProductFromCartId: string = "";
  getUserAddressId: string = "";
  deleteUserAddressId: string = "";
  addUserAddressId: string = "";
  editUserAddressId: string = "";
  getCouponsListId: string = "";
  applyCouponId: string = "";
  deleteCouponId: string = "";
  getStatesId: string = "";
  getCitiesId: string = "";
  updateItemQuantityId: string = "";
  productSuggestionsId: string = "";
  getShopFunctionCategoryDataId: string = "";
  getProductRecommendationId: string = "";
  addAddressToCurrentOrderId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      id: 0,
      token: "",
      myCartData: null,
      showAddress: '',
      quantity: 1,
      cartOrderItems: [],
      errorMessage: '',
      userAddressList: [],
      customerData: null,
      selectedAddress: '',
      addNewAddress: false,
      formAddress: '',
      formCity: ' ',
      formState: ' ',
      formCountry: ' ',
      formPincode: '',
      formContactNo: '',
      formEmail: '',
      addressError: false,
      cityError: false,
      stateError: false,
      countryError: false,
      pincodeError: false,
      contactNoError: false,
      emailError: false,
      addressErrorMsg: '',
      cityErrorMsg: '',
      stateErrorMsg: '',
      countryErrorMsg: '',
      pincodeErrorMsg: '',
      contactNoErrorMsg: '',
      emailErrorMsg: '',
      editAddress: false,
      editAddressId: '',
      isCouponApplied: false,
      openAddCouponModal: false,
      couponsList: [],
      selectedCoupon: 0,
      appliedCoupon: null,
      countries: [
        {
          id: 1,
          label: 'India',
          value: 'India',
          code: 'IN',
        }
      ],
      states: [],
      cities: [],
      productFunctions: [],
      productCategories: [],
      currentCartId: 0,
      productRecommendationData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.shopMyCartApiCallId:
          this.handleGetShopMyCartDataResponse(webResponseJson);
          break;

        case this.deleteProductFromCartId:
          this.handleDeleteProductFromCartResponse(webResponseJson);
          break;

        case this.getUserAddressId:
          this.handleGetUserAddressResponse(webResponseJson);
          break;

        case this.deleteUserAddressId:
          this.handleDeleteUserAddressResponse(webResponseJson);
          break;

        case this.addUserAddressId:
          this.handleAddUserAddressResponse(webResponseJson);
          break;

        case this.editUserAddressId:
          this.handleEditUserAddressResponse(webResponseJson);
          break;

        case this.getCouponsListId:
          this.handleGetCouponsListResponse(webResponseJson);
          break;

        case this.applyCouponId:
          this.handleApplyCouponResponse(webResponseJson);
          break;

        case this.deleteCouponId:
          this.handleDeleteCouponResponse(webResponseJson);
          break;

        case this.getStatesId:
          this.handleGetSattesResponse(webResponseJson);
          break;

        case this.getCitiesId:
          this.handleGetCitiesResponse(webResponseJson);
          break;

        case this.updateItemQuantityId:
          this.updateItemQuantityResponse(webResponseJson);
          break;

        case this.productSuggestionsId:
          this.handleProductSuggestionsResponse(webResponseJson);
          break;

        case this.getShopFunctionCategoryDataId:
          this.handleShopFunctionCategoryDataResponse(webResponseJson);
          break;

        case this.getProductRecommendationId:
          this.handleGetProductRecommendationResponse(webResponseJson);
          break;

        case this.addAddressToCurrentOrderId:
          this.handleAddAddressToOrderResponse(webResponseJson);
          break;

        default:
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
    this.getShopMyCartData();
    this.getCouponsList();
    this.productSuggestionsApi();
    this.getShopFunctionCategoryData();
    this.getRecommendedProductList();
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      toast.error("Please login to access cart.");
      this.props.navigation.goBack();
    }
  };

  getShopMyCartData = async () => {
    this.setState({
      isLoading: true,
    });
    this.shopMyCartApiCallId = await apiCall({
      endPoint: configJSON.getCartDataEndpoint,
      method: configJSON.getApiMethod,
    });
  };

  handleGetShopMyCartDataResponse = (response: OrderData) => {
    if (response?.data) {
      const myCartData = response.data.attributes;
      const cartOrderItems = myCartData.order_items;
      const customerData = myCartData.customer.attributes;
      let appliedCoupon = myCartData?.coupon_details?.attributes;
      let isCouponApplied = false;
      if (appliedCoupon && appliedCoupon.id) {
        isCouponApplied = true;
      }
      this.setState({
        myCartData,
        cartOrderItems,
        customerData,
        formContactNo: customerData.full_phone_number,
        formEmail: customerData.email,
        appliedCoupon,
        isCouponApplied,
        currentCartId: parseInt(response.data.id),
      }, () => {
        this.getUserAddress();
      });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error, myCartData: null, cartOrderItems: [] });
    }
    this.setState({ isLoading: false });
  }

  handleOrderBtn = async () => {
    if (this.state.showAddress == '') {
      this.setState({ showAddress: 'goToDelievryAddress' })
    } else {
      this.props.navigation.navigate("RazorPay", { id: this.state.currentCartId });
    }
  }

  handleQuantityChange = (event: any, orderItemId: string) => {
    const { cartOrderItems } = this.state;
    const newValue = event.target.value;
    const updatedCartItems = cartOrderItems.map(item =>
      item.id === orderItemId
        ? { ...item, attributes: { ...item.attributes, quantity: newValue } }
        : item
    );
    this.setState({ quantity: event.target.value as number, cartOrderItems: updatedCartItems }, () => {
      this.handleUpdateQuantity(orderItemId, newValue);
    });
  };

  deleteProductFromCart = async (productId: string) => {
    this.setState({ isLoading: true });
    this.deleteProductFromCartId = await apiCall({
      endPoint: `${configJSON.deleteProductFromCartEndpoint}/${productId}`,
      method: configJSON.deleteApiMethod,
    });
  };

  handleDeleteProductFromCartResponse = (response: { message: string, error: string }) => {
    if (response.message) {
      this.getShopMyCartData();
    }
    if (response.error) {
      toast.error(response.error || 'Failed to delete');
    }
    this.setState({ isLoading: false });
  }

  getUserAddress = async () => {
    this.setState({ isLoading: true });
    this.getUserAddressId = await apiCall({
      endPoint: configJSON.userAddressEndpoint,
      method: configJSON.getApiMethod,
    });
  };

  handleGetUserAddressResponse = (response: { data: UserAddressData[], error: string }) => {
    if (response?.data) {
      this.setState({ userAddressList: response.data });
    } else {
      this.setState({ userAddressList: [] });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  handleSelectAddress = async (id: string) => {
    const { currentCartId } = this.state;
    this.setState({ isLoading: true });
    this.addAddressToCurrentOrderId = await apiCall({
      endPoint: `${configJSON.addAddressToOrderEndpoint}?cart_id=${currentCartId}&address_id=${id}`,
      method: configJSON.patchApiMethod,
    });
    this.setState({ selectedAddress: id });
  }

  handleAddAddressToOrderResponse = (response: { message: string, error: string }) => {
    if (response.message) {
      this.setState({ isLoading: false });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
      this.setState({ isLoading: false });
    }
  }

  toggleAddNewAddressForm = () => {
    const { addNewAddress } = this.state;
    this.setState({
      addNewAddress: !addNewAddress,
      formAddress: '',
      formCity: '',
      formState: '',
      formCountry: '',
      formPincode: '',
      editAddress: false,
    });
  }

  handleInputChange = (event: any) => {
    const { name, value } = event.target;

    if (name === 'formPincode' && value.length > 6) {
      return false;
    }

    if (name === 'formContactNo' && value.length > 10) {
      return false;
    }

    if (value) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }), () => {
        switch (name) {
          case 'formAddress':
            this.setState({ addressError: false, addressErrorMsg: '' });
            break;

          case 'formCity':
            this.setState({ cityError: false, cityErrorMsg: '' });
            break;

          case 'formState':
            this.setState({ stateError: false, stateErrorMsg: '' });
            this.getCities();
            break;

          case 'formCountry':
            this.setState({ countryError: false, countryErrorMsg: '' });
            this.getStates();
            break;

          case 'formPincode':
            this.setState({ pincodeError: false, pincodeErrorMsg: '' });
            break;

          case 'formContactNo':
            this.setState({ contactNoError: false, contactNoErrorMsg: '' });
            break;

          case 'formEmail':
            this.setState({ emailError: false, emailErrorMsg: '' });
            break;

          default:
            break;
        }
      });

    }
  }

  handleMultipleLocations = (service_locations: any) => {
    return service_locations.data
      .map((location: any) => location.attributes.location_name)
      .join(', ');
  }

  deleteUserAddress = async (id: string) => {
    this.setState({ isLoading: true });
    this.deleteUserAddressId = await apiCall({
      endPoint: `${configJSON.userAddressEndpoint}/${id}`,
      method: configJSON.deleteApiMethod,
    });
  };

  handleDeleteUserAddressResponse = (response: { message: string, error: string }) => {
    if (!response.error) {
      this.getUserAddress();
    }
    if (response.error) {
      toast.error(response.error || 'Failed to delete');
    }
    this.setState({ isLoading: false });
  }

  handleSaveAddress = async () => {
    if (!this.checkFormValidation()) {
      return false;
    }

    const {
      formAddress,
      formCity,
      formState,
      formCountry,
      formPincode,
    } = this.state;

    const requestBody = {
      "address": {
        "address": formAddress,
        "address_type": "Home",
        "city": formCity,
        "state": formState,
        "country": formCountry,
        "pincode": formPincode
      }
    }
    this.setState({ isLoading: true });
    this.addUserAddressId = await apiCall({
      endPoint: `${configJSON.userAddressEndpoint}`,
      contentType: 'application/json',
      method: configJSON.postApiMethod,
      body: JSON.stringify(requestBody),
    });
  }

  handleAddUserAddressResponse = (response: { data: UserAddressData[], error: string }) => {
    if (response?.data) {
      this.setState({ addNewAddress: false });
      this.getUserAddress();
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  editUserAddress = (id: string) => {
    const { addNewAddress, userAddressList } = this.state;
    const address: any = userAddressList.find((item) => item.id === id)?.attributes;
    if (address) {
      this.setState({
        formAddress: address.address,
        formCity: address.city,
        formState: address.state,
        formCountry: address.country,
        formPincode: address.pincode,
        editAddress: true,
        editAddressId: id,
        addNewAddress: !addNewAddress,
      }, () => {
        this.getStates();
      });
    }
  }

  handleSaveEditAddress = async () => {
    if (!this.checkFormValidation()) {
      return false;
    }

    const {
      formAddress,
      formCity,
      formState,
      formCountry,
      formPincode,
      editAddressId,
    } = this.state;

    const requestBody = {
      "address": {
        "address": formAddress,
        "address_type": "Home",
        "city": formCity,
        "state": formState,
        "country": formCountry,
        "pincode": formPincode
      }
    }
    this.setState({ isLoading: true });
    this.editUserAddressId = await apiCall({
      endPoint: `${configJSON.userAddressEndpoint}/${editAddressId}`,
      contentType: 'application/json',
      method: configJSON.patchApiMethod,
      body: JSON.stringify(requestBody),
    });
  }

  handleEditUserAddressResponse = (response: { data: UserAddressData[], error: string }) => {
    if (response?.data) {
      this.setState({ addNewAddress: false });
      this.getUserAddress();
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  checkFormValidation = (): boolean => {
    let isValid = true;
    const {
      formAddress,
      formCity,
      formState,
      formCountry,
      formPincode,
      formContactNo,
      formEmail,
    } = this.state;

    if (!formAddress) {
      this.setState({ addressError: true, addressErrorMsg: 'Address is required' });
      isValid = false;
    }

    if (!formCity) {
      this.setState({ cityError: true, cityErrorMsg: 'City is required' });
      isValid = false;
    }

    if (!formState) {
      this.setState({ stateError: true, stateErrorMsg: 'State is required' });
      isValid = false;
    }

    if (!formCountry) {
      this.setState({ countryError: true, countryErrorMsg: 'Country is required' });
      isValid = false;
    }

    if (!formPincode) {
      this.setState({ pincodeError: true, pincodeErrorMsg: 'Pincode is required' });
      isValid = false;
    }

    if (!formContactNo) {
      this.setState({ contactNoError: true, contactNoErrorMsg: 'Contact no. is required' });
      isValid = false;
    }

    if (!formEmail) {
      this.setState({ emailError: true, emailErrorMsg: 'Email is required' });
      isValid = false;
    }

    return isValid;
  }

  handleOpenCouponModal = () => {
    this.setState({ openAddCouponModal: true });
  }

  handleCloseCouponModal = () => {
    this.setState({ openAddCouponModal: false, selectedCoupon: 0 });
  }

  getCouponsList = async () => {
    this.setState({ isLoading: true });
    this.getCouponsListId = await apiCall({
      endPoint: configJSON.getCouponsEndpoint,
      method: configJSON.getApiMethod,
    });
  };

  handleGetCouponsListResponse = (response: { data: CouponsData[], error: string }) => {
    if (response?.data) {
      this.setState({ couponsList: response.data });
    } else {
      this.setState({ couponsList: [] });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  handleSelectedCoupon = (id: number) => {
    const { selectedCoupon } = this.state;
    if (selectedCoupon === id) {
      this.setState({ selectedCoupon: 0 });
    } else {
      this.setState({ selectedCoupon: id });
    }
  }

  applyCoupon = async () => {
    const { selectedCoupon } = this.state;
    this.setState({ isLoading: true });
    this.applyCouponId = await apiCall({
      endPoint: `${configJSON.applyCouponEndpoint}?coupon_id=${selectedCoupon}`,
      method: configJSON.patchApiMethod,
    });
  };

  handleApplyCouponResponse = (response: { data: any, error: string }) => {
    if (response?.data) {
      this.handleCloseCouponModal();
      this.getShopMyCartData();
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  deleteCoupon = async () => {
    this.setState({ isLoading: true });
    this.deleteCouponId = await apiCall({
      endPoint: `${configJSON.applyCouponEndpoint}?coupon_id=0`,
      method: configJSON.patchApiMethod,
    });
  };

  handleDeleteCouponResponse = (response: { data: any, error: string }) => {
    if (response?.data) {
      this.getShopMyCartData();
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  renderStringValueDropdown = (value: string, dropdownData: DropdownData[]) => {
    return dropdownData.find((item) => item.value === value)?.label;
  }

  getStates = async () => {
    this.setState({ isLoading: true });
    const { formCountry, countries } = this.state;
    const countryCode = countries.find((item) => item.value === formCountry)?.code;
    this.getStatesId = await apiCall({
      endPoint: configJSON.getStatesEndpoint + `?country_code=${countryCode}`,
      method: configJSON.getApiMethod,
    });
  };

  handleGetSattesResponse = (response: { data: { state_code: string, state_name: string }[], error: string }) => {
    if (response?.data) {
      const states = response.data.map((item, index) => {
        return {
          id: index + 1,
          label: item.state_name,
          value: item.state_name,
          code: item.state_code,
        }
      });
      this.setState({ states }, () => {
        this.getCities();
      });
    } else {
      this.setState({ states: [] });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  getCities = async () => {
    this.setState({ isLoading: true });
    const { formCountry, countries, formState, states } = this.state;
    const countryCode = countries.find((item) => item.value === formCountry)?.code;
    const stateCode = states.find((item) => item.value === formState)?.code;

    this.getCitiesId = await apiCall({
      endPoint: configJSON.getCitiesEndpoint + `?country_code=${countryCode}&state_code=${stateCode}`,
      method: configJSON.getApiMethod,
    });
  };

  handleGetCitiesResponse = (response: { data: { city_name: string }[], error: string }) => {
    if (response?.data) {
      const cities = response.data.map((item, index) => {
        return {
          id: index + 1,
          label: item.city_name,
          value: item.city_name,
        }
      });
      this.setState({ cities });
    } else {
      this.setState({ cities: [] });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  getRecommendedProductList = async () => {
    this.setState({ isLoading: true });
    this.getProductRecommendationId = await apiCall({
      endPoint: configJSON.getProductRecommendation,
      method: configJSON.getApiMethod,
    });
  };

  handleGetProductRecommendationResponse = (response: { data: any[], error: string }) => {
    if (response?.data) {
      this.setState({ productRecommendationData: response.data });
    } else {
      this.setState({ productRecommendationData: [] });
    }
    if (response.error) {
      this.setState({ errorMessage: response.error });
    }
    this.setState({ isLoading: false });
  }

  getLocationText = (locations: { data: ServiceLocation[] }): string => {
    let locationsText = '';
    if (locations.data && locations.data.length > 0) {
      locations.data.forEach((item: any) => {
        locationsText += item.attributes.location_name;
        if (locations.data.indexOf(item) != locations.data.length - 1) {
          locationsText += ', ';
        }
      })
    }
    return locationsText;
  }

  getProductImage = (mediaFiles: MediaFile[]): string => {
    let image = productDefaultImage;
    if (mediaFiles.length > 0) {
      image = mediaFiles[0].url;
    }
    return image;
  }

  handleUpdateQuantity = async (cartItemId: string, quantityValue: number) => {
    const { cartOrderItems } = this.state;
    const product = cartOrderItems.find((item) => item.id === cartItemId)?.attributes.product;
    const variant = product?.attributes.product_variants;

    if (variant && variant.id) {
      const requestBody = {
        cart: {
          product_id: product?.attributes.id,
          product_size_id: parseInt(variant.attributes.product_size.id),
          product_color_id: parseInt(variant.attributes.product_color.id),
          quantity: quantityValue,
        }
      }
      this.updateItemQuantityApi(requestBody);
    }
  }

  updateItemQuantityApi = async (requestBody: any) => {
    this.setState({ isLoading: true });
    this.updateItemQuantityId = await apiCall({
      endPoint: configJSON.addToCartEndpoint,
      method: configJSON.postApiMethod,
      contentType: 'application/json',
      body: JSON.stringify(requestBody),
    });
  }

  updateItemQuantityResponse = (response: { data: any, error: string }) => {
    if (response?.data) {
      this.getShopMyCartData();
    }
    if (response?.error) {
      toast.error("Please select less quantity, few items left.", { position: "bottom-right" });
      this.getShopMyCartData();
    }
  }

  productSuggestionsApi = async () => {
    this.setState({ isLoading: true });
    this.productSuggestionsId = await apiCall({
      endPoint: configJSON.productSuggestionEndpoint,
      method: configJSON.getApiMethod,
      contentType: 'application/json',
    });
  }

  handleProductSuggestionsResponse = (response: { data: any, error: string }) => {
    if (response?.data) {
      console.log("response: ", response);
    }
    if (response?.error) {
      toast.error(response.error, { position: "bottom-right" });
    }
  }

  getShopFunctionCategoryData = async () => {
    this.setState({ isLoading: true });
    this.getShopFunctionCategoryDataId = await apiCall({
      endPoint: configJSON.shopByCategoryEndpoint,
      method: configJSON.getApiMethod,
    });
  };

  handleShopFunctionCategoryDataResponse = (response:
    {
      data:
      {
        attributes:
        {
          functions: Array<any>,
          categories: Array<any>,
        }
      },
      errors: any
    }) => {
    if (response?.data) {
      const data = response.data.attributes;

      const functions = data.functions;
      const productFunctions = functions.map((item) => {
        return {
          id: item.attributes.id,
          label: item.attributes.name,
          value: item.attributes.name,
        }
      });

      const productCategories = data.categories.map((item) => {
        return {
          id: item.attributes.id,
          label: item.attributes.name,
          value: item.attributes.name,
        }
      });

      this.setState({ productFunctions, productCategories });
    }
    if (response.errors) {
      toast.error(response.errors);
    }
    this.setState({ isLoading: false });
  };

  handleViewAllRedirection = () => {
    const { productFunctions, productCategories } = this.state;
    let params = {
      type: '',
      name: '',
    }

    const sources = [
      { array: productFunctions, type: 'function' },
      { array: productCategories, type: 'category' }
    ];

    const randomSource = sources[Math.trunc(this.selectRandomIndex(sources.length))];
    const randomItem = randomSource.array[Math.trunc(this.selectRandomIndex(randomSource.array.length))];

    if (randomItem) {
      params = {
        type: randomSource.type,
        name: randomItem.label
      };
      this.props.navigation.navigate("ShopByOccassion", params);
    } else {
      toast.warning("No function or category available to redirect")
    }

  }

  selectRandomIndex = (arrayLength: number) => {
    const timestamp = Date.now();
    const index = timestamp % arrayLength;
    return index;
  };

  // Customizable Area End
}
