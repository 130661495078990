import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  id?: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  loading: boolean;
  anchorEl: HTMLElement | null;
  ordersData: any[];
  // Customizable Area End
}
interface SS { }

export default class OrderManagementViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getOrdersDataCallId: string = "";
  markOrderCompleteId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      anchorEl: null,
      ordersData: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getOrdersDataAPI();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webApiRequestCallId === this.getOrdersDataCallId) {
        this.handleGetOrdersDataResponse(webResponseJson)
      }

      if (webApiRequestCallId === this.markOrderCompleteId) {
        this.handleMarkOrderCompleteResponse(webResponseJson)
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getOrdersDataAPI = async () => {
    this.setState({ loading: true });
    this.getOrdersDataCallId = await apiCall({
      endPoint: `bx_block_order_management/orders/fetch_vendor_orders`,
      method: "GET",
      token: this.state.token,
    });
  }

  handleGetOrdersDataResponse = (response: { data: any[] }) => {
    if (response.data) {
      const orders = response.data.map((item) => {
        const order = item.attributes;
        return {
          id: order.id,
          code: order.order_number,
          date: moment(order.placed_at).format("DD MMM, YYYY"),
          quantity: order.order_items_count,
          price: `₹${order.total_vendor_price}`,
          paid: order.paid,
          address: order.address,
          location: order.address?.data?.attributes?.address,
          status: order.status,
        }
      });
      this.setState({ ordersData: orders });
    } else {
      this.setState({ ordersData: [] });
    }
    this.setState({ loading: false });
  }

  handleMarkComplete = async (id: number) => {
    this.handleClose();
    console.log("mark as complete: ", id);
    this.setState({ loading: true });
    this.markOrderCompleteId = await apiCall({
      endPoint: `/bx_block_order_management/orders/${id}/mark_order_as_completed`,
      method: "PATCH",
    });
  }

  handleMarkOrderCompleteResponse = (response: any) => {
    if (response?.message) {
      toast.success("Order marked as complete");
      this.getOrdersDataAPI();
    }
    this.setState({ loading: false });
  }
  // Customizable Area End
}
