import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Grid, Button, Divider, Select, MenuItem, Radio, TextField, InputLabel, Modal, Checkbox, Card, CardActionArea, CardContent, Rating, CardActions, Skeleton } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IosShareIcon from '@mui/icons-material/IosShare';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { AiOutlineClose } from "react-icons/ai";

// Customizable Area End
import ShopMyCartController, { Props, OrderItem, ProductAttributes } from './ShopMyCartController.web';

export default class ShopMyCart extends ShopMyCartController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    noItemsErrorDisplay = () => {
        return (
            <Box width={"100%"} textAlign={"center"} display={"none"}>
                <Typography style={{ color: "red", fontSize: "12px" }}>Your cart is empty!</Typography>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        const {
            myCartData,
            showAddress,
            isLoading,
            cartOrderItems,
            userAddressList,
            customerData,
            selectedAddress,
            addNewAddress,
            formAddress,
            formCity,
            formState,
            formCountry,
            formPincode,
            formContactNo,
            formEmail,
            addressError,
            cityError,
            stateError,
            countryError,
            pincodeError,
            contactNoError,
            emailError,
            addressErrorMsg,
            cityErrorMsg,
            stateErrorMsg,
            countryErrorMsg,
            pincodeErrorMsg,
            contactNoErrorMsg,
            emailErrorMsg,
            editAddress,
            isCouponApplied,
            openAddCouponModal,
            couponsList,
            selectedCoupon,
            appliedCoupon,
            countries,
            states,
            cities,
        } = this.state;

        return (
            // Customizable Area Start
            <HeaderFooterLayout navigation={this.props.navigation}>
                <Loader isLoading={isLoading} />
                <WeddingShopStyle component="section" id="inspiration">
                    {cartOrderItems.length > 0 &&
                        <Box className="firstSection">
                            <Typography className="myCartHead">{showAddress == "goToDelievryAddress" ? "Select Delivery Address" : "My Cart"}</Typography>
                            <Box className="cartSection">
                                {showAddress != "goToDelievryAddress" &&
                                    <Box className="cartData">
                                        {cartOrderItems.map((cartData: OrderItem, index: number) => {
                                            const product: ProductAttributes = cartData.attributes.product.attributes;
                                            return (
                                                <>
                                                    <Box key={index} className="cartBox">
                                                        <Box className="detailsBox">
                                                            <img src={this.getProductImage(product.media_files)} alt="product-image" className="image" />
                                                            <Box className="detailsSection">
                                                                <Box>
                                                                    <Typography className="productName">{product.name}</Typography>
                                                                    <Typography className="location">{this.getLocationText(product.service_locations)}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="quantity" component={"span"}>Qty</Typography>
                                                                    <Select
                                                                        data-test-id={`quantityDropdown${index}`}
                                                                        className="quantity"
                                                                        labelId="quantity-select"
                                                                        displayEmpty
                                                                        value={cartData.attributes.quantity}
                                                                        onChange={(event: any) => this.handleQuantityChange(event, cartData.id)}
                                                                        sx={{
                                                                            border: "1px solid white",
                                                                            fontSize: "16px",
                                                                            fontWeight: 700,
                                                                            fontFamily: "inter",
                                                                            '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                                                                        }}
                                                                    >
                                                                        {[...Array(10).keys()].map((num) => (
                                                                            <MenuItem key={num + 1} value={num + 1}>
                                                                                {num + 1}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </Box>
                                                                <Box paddingBottom={"20px"}>
                                                                    <Typography className="deliver" style={{ marginBottom: "10px" }}><span style={{ color: '#78716C' }}>FREE delivery </span><span style={{ color: '#292524' }}>Mon, 25 Sep</span></Typography>
                                                                    <Box className="cartBtn">
                                                                        <Button className="deleteBtn" data-test-id={`deleteProductBtn${index}`} onClick={() => this.deleteProductFromCart(cartData.id)} style={{ paddingLeft: '0px', marginLeft: '-5px' }}><DeleteOutlineIcon /><span>Delete</span></Button>
                                                                        <Button className="shareBtn"><FavoriteBorderIcon style={{ color: 'black' }} /><span>Save for Letter</span></Button>
                                                                        <Button className="shareBtn"><IosShareIcon style={{ color: 'black' }} /><span>Share</span></Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Typography className="price"><CurrencyRupeeIcon fontSize="small" />{cartData.attributes.price}</Typography>
                                                            {cartData.attributes.discount && <Typography className="offer">{cartData.attributes.discount}% Off</Typography>}
                                                        </Box>
                                                    </Box>
                                                    <Divider className="divider" />
                                                </>
                                            )
                                        })}
                                    </Box>
                                }

                                {showAddress == "goToDelievryAddress" &&
                                    <>
                                        {!addNewAddress ?
                                            <Box className="cartData">
                                                {userAddressList.map((item, index) => {
                                                    const address = item.attributes;
                                                    return (
                                                        <>
                                                            <Box key={item.id} display={"flex"} flexDirection={"row"} gap={"20px"}>
                                                                <Box>
                                                                    <CustomRadio data-test-id={`selectAddressRadio${index}`} onClick={() => this.handleSelectAddress(item.id)} checked={selectedAddress === item.id} />
                                                                </Box>
                                                                <Box className="userAdd" padding={"10px 0px"}>
                                                                    <Box className="userAdd">
                                                                        <Box className='userName'>
                                                                            <Typography className="deliver">{customerData?.full_name}</Typography>
                                                                            <Button className='homeBtn'>{address.address_type}</Button>
                                                                        </Box>
                                                                        <Typography className="discountOpt">
                                                                            {address.address}
                                                                        </Typography>
                                                                        <Typography className="discountOpt">
                                                                            {`${address.city}, ${address.state}, ${address.country}`}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box className="userAdd">
                                                                        <Typography className="discountOpt">Mobile number:<span style={{ color: 'black' }}> +91 {customerData?.full_phone_number}</span></Typography>
                                                                        <Box className="cartBtn">
                                                                            <Button data-test-id={`deleteAddressButton${index}`} onClick={() => this.deleteUserAddress(item.id)} className="deleteBtn" style={{ paddingLeft: '0px', marginLeft: '-5px' }}><DeleteOutlineIcon /><span>Delete</span></Button>
                                                                            <Button data-test-id={`editAddressButton${index}`} onClick={() => this.editUserAddress(item.id)} className="shareBtn"><ModeEditIcon style={{ color: 'black' }} /><span>Edit</span></Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Divider className="divider" />
                                                        </>
                                                    )
                                                })}
                                                <Box>
                                                    <Typography data-test-id="addNewDeliveryAddress" className="addNewAddressButton" onClick={() => this.toggleAddNewAddressForm()} >+ Add New Delivery Address</Typography>
                                                </Box>
                                            </Box>
                                            :
                                            <Box className="cartData">
                                                <Box width={"94%"} height={"100%"} padding={" 10px 20px 20px 20px"} borderRadius={"10px"} style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                                    <Box height={"max-content"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                        <Typography className="addNewAddressButton"> {editAddress ? 'Edit' : '+ Add New '} Delivery Address</Typography>
                                                        <Typography data-test-id="closeAddressFormButton" className="addNewAddressButton" onClick={() => this.toggleAddNewAddressForm()} ><AiOutlineClose /> </Typography>
                                                    </Box>
                                                    <CustomAddressForm>
                                                        <Grid container spacing={3} marginTop={"20px"} width={"100%"}>
                                                            <Grid item xs={12}>
                                                                <InputLabel>Address <Typography className="required" component={"span"}>*</Typography></InputLabel>
                                                                <TextField
                                                                    style={{ width: "100%" }}
                                                                    name="formAddress"
                                                                    placeholder="Enter address"
                                                                    data-test-id="address-input"
                                                                    variant="outlined"
                                                                    value={formAddress}
                                                                    onChange={this.handleInputChange}
                                                                    error={addressError}
                                                                    helperText={addressErrorMsg}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <InputLabel id="country-dropdown-label">Country <Typography className="required" component={"span"}>*</Typography></InputLabel>
                                                                <Select
                                                                    data-test-id="country-dropdown"
                                                                    labelId="country-dropdown-label"
                                                                    id="countries-dropdown"
                                                                    name="formCountry"
                                                                    value={formCountry}
                                                                    placeholder="Select Country"
                                                                    onChange={this.handleInputChange}
                                                                    error={countryError}
                                                                    style={{ width: "100%" }}
                                                                    renderValue={(selected) => this.renderStringValueDropdown(selected, countries) || 'Select Country'}
                                                                >
                                                                    <MenuItem value="">Select Country</MenuItem>
                                                                    {countries.map((item) => (
                                                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {countryError && <Typography className="dropdownErrorMsg">{countryErrorMsg}</Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <InputLabel id="state-dropdown-label">State <Typography className="required" component={"span"}>*</Typography></InputLabel>
                                                                <Select
                                                                    data-test-id="state-dropdown"
                                                                    labelId="state-dropdown-label"
                                                                    id="states-dropdown"
                                                                    name="formState"
                                                                    value={formState}
                                                                    placeholder="Select State"
                                                                    onChange={this.handleInputChange}
                                                                    error={stateError}
                                                                    style={{ width: "100%" }}
                                                                    renderValue={(selected) => this.renderStringValueDropdown(selected, states) || 'Select State'}
                                                                >
                                                                    <MenuItem value="">Select State</MenuItem>
                                                                    {states.map((item) => (
                                                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {stateError && <Typography className="dropdownErrorMsg">{stateErrorMsg}</Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <InputLabel>City <Typography className="required" component={"span"}>*</Typography></InputLabel>
                                                                <Select
                                                                    data-test-id="city-dropdown"
                                                                    labelId="city-dropdown-label"
                                                                    id="city-dropdown"
                                                                    name="formCity"
                                                                    value={formCity}
                                                                    placeholder="Select City"
                                                                    onChange={this.handleInputChange}
                                                                    error={cityError}
                                                                    style={{ width: "100%" }}
                                                                    renderValue={(selected) => this.renderStringValueDropdown(selected, cities) || 'Select City'}
                                                                >
                                                                    <MenuItem value="">Select City</MenuItem>
                                                                    {cities.map((item) => (
                                                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {cityError && <Typography className="dropdownErrorMsg">{cityErrorMsg}</Typography>}
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <InputLabel>Pin Code <Typography className="required" component={"span"}>*</Typography></InputLabel>
                                                                <TextField
                                                                    style={{ width: "100%" }}
                                                                    name="formPincode"
                                                                    placeholder="Enter pin code"
                                                                    data-test-id="pincode-input"
                                                                    variant="outlined"
                                                                    value={formPincode}
                                                                    onChange={this.handleInputChange}
                                                                    error={pincodeError}
                                                                    helperText={pincodeErrorMsg}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <InputLabel>Contact number<Typography className="required" component={"span"}>*</Typography></InputLabel>
                                                                <TextField
                                                                    style={{ width: "100%" }}
                                                                    name="formContactNo"
                                                                    placeholder="Enter contact no."
                                                                    data-test-id="contact-input"
                                                                    variant="outlined"
                                                                    value={formContactNo}
                                                                    onChange={this.handleInputChange}
                                                                    error={contactNoError}
                                                                    helperText={contactNoErrorMsg}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                <InputLabel>Email<Typography className="required" component={"span"}>*</Typography></InputLabel>
                                                                <TextField
                                                                    style={{ width: "100%" }}
                                                                    name="formEmail"
                                                                    placeholder="Enter email"
                                                                    data-test-id="email-input"
                                                                    variant="outlined"
                                                                    value={formEmail}
                                                                    onChange={this.handleInputChange}
                                                                    error={emailError}
                                                                    helperText={emailErrorMsg}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button data-test-id="saveAddressButton" className="proceedBtn" type="button" onClick={() => editAddress ? this.handleSaveEditAddress() : this.handleSaveAddress()}>
                                                                    Save Address
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </CustomAddressForm>

                                                </Box>
                                            </Box>
                                        }
                                    </>
                                }

                                {myCartData &&
                                    <Box className="cartDetails">
                                        <Typography className="couponHead">Coupons</Typography>
                                        {isCouponApplied && appliedCoupon ?
                                            <Box style={{ width: "100%" }}>
                                                <Box className="offerDetails space-between">
                                                    <Typography className="offerDetails deliver"><LocalOfferOutlinedIcon style={{ paddingRight: "20px", textTransform: 'uppercase' }} />{appliedCoupon.coupon_code}</Typography>
                                                    <Typography className="status">Applied</Typography>
                                                </Box>
                                                <Box className="offerDetails space-between">
                                                    <Typography className="offerDetails saved">You saved <CurrencyRupeeIcon fontSize="small" />{appliedCoupon.amount}</Typography>
                                                    <Button data-test-id="deleteCouponButton" onClick={() => this.deleteCoupon()} className="deleteBtn" style={{ paddingRight: '0px' }}><DeleteOutlineIcon /><span>Delete</span></Button>
                                                </Box>
                                            </Box>
                                            :
                                            <Box style={{ width: "100%" }}>
                                                <Box className="offerDetails space-between">
                                                    <Typography className="offerDetails deliver"><LocalOfferOutlinedIcon style={{ paddingRight: "20px" }} />Apply Coupon</Typography>
                                                    <Typography data-test-id="openApplyCouponModal" onClick={this.handleOpenCouponModal} className="status" style={{ color: '#DC2626', cursor: 'pointer' }} >Apply</Typography>
                                                </Box>
                                            </Box>
                                        }
                                        <Box>
                                            <Typography className="couponHead">Price Details ({myCartData.total_items} items)</Typography>
                                            <Box className="offerSection">
                                                <Box className="offerDetails space-between">
                                                    <Typography className="discountOpt">Total MRP</Typography>
                                                    <Typography className="offerDetails deliver"><CurrencyRupeeIcon fontSize="small" />{myCartData.total_mrp}</Typography>
                                                </Box>
                                                <Box className="offerDetails space-between">
                                                    <Typography className="discountOpt">Discount on MRP</Typography>
                                                    <Typography className="offerDetails saved">-<CurrencyRupeeIcon fontSize="small" />{myCartData.discount_on_mrp}</Typography>
                                                </Box>
                                                <Box className="offerDetails space-between">
                                                    <Typography className="discountOpt">Coupon Discount </Typography>
                                                    <Typography className="offerDetails saved">-<CurrencyRupeeIcon fontSize="small" />{appliedCoupon?.amount || 0}</Typography>
                                                </Box>
                                                <Box className="offerDetails space-between">
                                                    <Typography className="offerDetails discountOpt">Shipping Fee
                                                        <Typography className="knowMoreText" component={"span"}>Know more</Typography>
                                                    </Typography>
                                                    <Typography className="offerDetails discountOpt"><CurrencyRupeeIcon fontSize="small" />0</Typography>
                                                </Box>
                                                <Divider className="divider" />
                                                <Box className="offerDetails space-between">
                                                    <Typography className="deliver">Total Amount</Typography>
                                                    <Typography className="offerDetails deliver"><CurrencyRupeeIcon fontSize="small" />{myCartData.total_amount}</Typography>
                                                </Box>
                                                <Divider className="divider" />
                                                <Button disabled={showAddress == "goToDelievryAddress" && !selectedAddress} className="proceedBtn" type="button" data-test-id="handleCreateCatalogoueApi" onClick={this.handleOrderBtn}>
                                                    {showAddress != "goToDelievryAddress" ? "Next" : "Place Order"}
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    }
                    {isLoading &&
                        <Grid container spacing={5} padding={"100px"}>
                            <Grid item xs={8} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'80%'} height={'100px'} />
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'60%'} height={'20px'} />
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'40%'} height={'10px'} />

                                <Skeleton sx={{ background: "#CDCDCD", marginTop: "30px" }} animation="wave" variant="rounded" width={'80%'} height={'100px'} />
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'60%'} height={'20px'} />
                                <Skeleton sx={{ background: "#CDCDCD", marginBottom: "30px" }} animation="wave" variant="rounded" width={'40%'} height={'10px'} />

                                <Skeleton sx={{ background: "#CDCDCD", marginTop: "30px" }} animation="wave" variant="rounded" width={'80%'} height={'100px'} />
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'60%'} height={'20px'} />
                                <Skeleton sx={{ background: "#CDCDCD", marginBottom: "30px" }} animation="wave" variant="rounded" width={'40%'} height={'10px'} />
                            </Grid>
                            <Grid item xs={4} style={{ display: "flex", alignItems: 'end', flexDirection: "column", gap: "10px" }}>
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'50px'} />
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'50%'} height={'20px'} />
                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'30%'} height={'10px'} />

                                <Skeleton sx={{ background: "#CDCDCD", marginTop: "30px" }} animation="wave" variant="rounded" width={'100%'} height={'20px'} />
                                <Skeleton sx={{ background: "#CDCDCD", marginBottom: "30px" }} animation="wave" variant="rounded" width={'10%'} height={'20px'} />

                                <Skeleton sx={{ background: "#CDCDCD", marginTop: "30px" }} animation="wave" variant="rounded" width={'100%'} height={'20px'} />
                                <Skeleton sx={{ background: "#CDCDCD", marginBottom: "30px" }} animation="wave" variant="rounded" width={'10%'} height={'20px'} />

                                <Skeleton sx={{ background: "#CDCDCD", marginTop: "30px" }} animation="wave" variant="rounded" width={'100%'} height={'20px'} />
                                <Skeleton sx={{ background: "#CDCDCD", marginBottom: "30px" }} animation="wave" variant="rounded" width={'10%'} height={'20px'} />

                                <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'50px'} />

                            </Grid>
                        </Grid>
                    }
                    {!isLoading && cartOrderItems.length === 0 &&
                        <Box className="firstSection">
                            <Typography className="myCartHead">{showAddress == "goToDelievryAddress" ? "Select Delivery Address" : "My Cart"}</Typography>
                            <Box className="cartSection" height={"50vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography style={{ color: 'red', fontWeight: 600, fontFamily: 'Poppins' }} align="center">Your cart is empty!</Typography>
                            </Box>
                        </Box>
                    }
                    <Box marginTop={"100px"} width={"100%"} height={"100%"}>
                        <Typography className="productSectionTitle">You might also like this</Typography>
                        <Grid container spacing={3} padding={"0px 100px 0px 100px"}>
                            {this.state.productRecommendationData.map((product, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ flexGrow: 1, borderRadius: '12px', overflow: 'hidden' }}>
                                    <Card
                                        key={index}
                                        className="productCard"
                                        sx={{ boxShadow: 3, marginBottom: "10px", borderRadius: "12px", overflow: "hidden" }}
                                    >
                                        <Box className="likeIconBox">
                                            <FavoriteBorderIcon sx={{ color: 'white' }} />
                                        </Box>
                                        <CardActionArea>
                                            <img className='cardsImg' src={product.attributes.media_files[0].url} alt={product.attributes.name} style={{ width: '100%', height: '450px', objectFit: 'cover' }} />
                                            <CardContent>
                                                <Box className="productTitleRatingBox" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography className="productTitle productTitleEllipsis" variant="h6">
                                                        {product.attributes.name}
                                                    </Typography>
                                                    <Box className="productRatingBox" sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Rating value={5} max={1} precision={1} readOnly size="small" />
                                                        <Typography className="ratingText" sx={{ marginLeft: '4px', fontSize: '0.875rem' }}>
                                                            4.5 (678 Ratings)
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Typography className="productLocation" variant="body2" color="textSecondary">
                                                    {this.handleMultipleLocations(product.attributes.service_locations)}
                                                </Typography>
                                            </CardContent>
                                            <CardActions style={{ padding: '0 8px 15px 15px' }}>
                                                <Typography className="productPriceText" variant="h6">
                                                    ₹ {product.attributes.price}
                                                </Typography>
                                            </CardActions>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={"50px"}>
                            <Button onClick={this.handleViewAllRedirection} className="viewAllButton" sx={{ width: "207px" }}>View all</Button>
                        </Box>
                    </Box>
                </WeddingShopStyle>
                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={openAddCouponModal}
                    onClose={this.handleCloseCouponModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box style={modalStyle.mainContainer}>
                        <Box style={modalStyle.header}>
                            <Typography style={modalStyle.applyCouponTitle}> Apply Coupon </Typography>
                            <Typography datat-test-id="modalCloseButton" style={{ ...modalStyle.applyCouponTitle, ...{ cursor: 'pointer' } }} onClick={() => this.handleCloseCouponModal()}><AiOutlineClose /> </Typography>
                        </Box>
                        <Box width={"100%"} height={"500px"} overflow={"auto"}>
                            <Grid container padding={"24px"}>
                                <Grid item xs={12}>
                                    <TextField style={modalStyle.couponInput} placeholder="Enter coupon code" />
                                </Grid>
                                {couponsList.length > 0 ?
                                    <>
                                        {couponsList.map((coupon) => (
                                            <Grid item xs={12} style={modalStyle.couponItem}>
                                                <Box padding={"5px 10px 20px 5px"}>
                                                    <CustomCheckBox onClick={() => this.handleSelectedCoupon(coupon.id)} checked={selectedCoupon === coupon.id} />
                                                </Box>
                                                <Box>
                                                    <Typography style={modalStyle.couponCode}> {coupon.coupon_code} </Typography>
                                                    <Typography style={modalStyle.savedText}> You Saved ₹ {coupon.amount} </Typography>
                                                    <Typography style={modalStyle.descriptionText}>30% off up to ₹ {coupon.amount} on min purchase of ₹ 1,000 </Typography>
                                                    <Typography style={modalStyle.expiryDateText}>Exp on 12 Dec 2024 </Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                        <Grid item xs={12} style={modalStyle.buttonFooter}>
                                            <Button style={modalStyle.cancelButton} type="button" onClick={() => this.handleCloseCouponModal()}> Cancel </Button>
                                            <Button style={modalStyle.applyButton} disabled={!selectedCoupon} type="button" onClick={() => this.applyCoupon()}> Apply </Button>
                                        </Grid>
                                    </>
                                    :
                                    <Grid item xs={12} style={{ height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography>No coupons found!</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Modal>
                {this.noItemsErrorDisplay()}
            </HeaderFooterLayout >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CustomAddressForm = styled(Box)({
    marginBottom: "16px",
    "& .common-form-label": {
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#334155",
        marginBottom: "4px",
        display: "block",
        "& span": {
            color: "#DC2626",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "22px",
        },
    },
    "& .MuiInputBase-input": {
        padding: "10.5px 8px ",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        color: "#0F172A",
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#A8A29E",
        opacity: 1,
    },
    "& .MuiInputBase-root.Mui-error": {
        borderRadius: "8px",
    },
    "& .MuiInputBase-root": {
        borderRadius: "0px",
        backgroundColor: "#FFFFFF",
    },
    "& .MuiFormHelperText-root": {
        color: "#DC2626",
        margin: "8px 0 0",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171 !important",
        borderWidth: "1px !important",
        borderStyle: "solid",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderLeft: "0px",
        borderBottom: "1px",
        borderRight: "0px",
        borderTop: "0px",
        borderStyle: "solid",
        borderColor: "#E2E8F0",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        borderRadius: "8px",
        borderWidth: "1px",
    },
});

const CustomRadio = styled(Radio)({
    '&.MuiRadio-root': {
        color: '#64748B !important',
    },
    '& .MuiSvgIcon-root': {
        color: "#6200EA "
    },
});

const CustomCheckBox = styled(Checkbox)({
    '&.MuiRadio-root': {
        color: '#64748B !important',
    },
    '& .MuiSvgIcon-root': {
        color: "#6200EA "
    },
});

const modalStyle = {
    mainContainer: {
        background: '#FFF',
        width: '800px',
        minHeight: '500px',
        borderRadius: '10px',
        overflow: 'hidden',
    },
    header: {
        height: "max-content",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
        borderBottom: "1px solid #D6D3D1",
    },
    applyCouponTitle: {
        fontFamily: 'Poppins',
        fontSize: '24px',
        lineHeight: '24px',
        fontWeight: 600,
        color: '#292524',
    },
    couponInput: {
        width: '100%',
        height: '54px',
    },
    couponItem: {
        display: 'flex',
        flexFlow: 'row',
        marginTop: '30px',
    },
    couponCode: {
        width: 'max-content',
        fontFamily: 'Inter',
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: 700,
        color: '#7F1187',
        border: '1px solid #7F1187',
        borderStyle: 'dashed',
        padding: '15px 20px',
        textTransform: 'uppercase' as const,
    },
    savedText: {
        marginTop: "10px",
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
        color: '#000',
    },
    descriptionText: {
        marginTop: "10px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 400,
        color: '#A8A29E',
    },
    expiryDateText: {
        marginTop: "20px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 400,
        color: '#78716C',
    },
    buttonFooter: {
        display: 'flex',
        justifyContent: 'end',
        flexFlow: 'row',
        margin: '25px 0px 25px 0px',
    },
    applyButton: {
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "227px",
        cursor: "pointer",
        "&:hover": {
            background: "#7F1287"
        }
    } as const,
    cancelButton: {
        color: "#94A3B8",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#E2E8F0",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "227px",
        cursor: "pointer",
        "&:hover": {
            background: "#7F1287"
        },
        marginRight: '20px',
    } as const,
}

const WeddingShopStyle = styled(Box)({
    padding: "56px 0 0",
    "& .firstSection": {
        width: "90%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "32px",

    },
    "& .myCartHead": {
        color: "#292524",
        fontSize: "20px",
        fontWeight: 600,
        fontFamilt: "Poppins",
        lineHeight: "24px",
        padding: '20px 0px',
        borderBottom: "1px solid #A8A29E",
    },
    "& .cartSection": {
        display: 'flex',
        width: "100%",
    },
    "& .cartData": {
        width: "70%",
        padding: "0px 24px 24px 0px",
        borderRight: "2px solid #D6D3D1"
    },
    "& .cartDetails": {
        width: "30%",
        padding: "5px 20px",
        display: 'flex',
        flexDirection: "column",
        gap: "20px"
    },
    "& .price": {
        color: '#801188',
        fontSize: "16px",
        fontWeight: 600,
        fontFamilt: "Poppins",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        paddingBottom: "5px"
    },
    "& .offer": {
        padding: "5px 0px",
        backgroundColor: "#FF0101",
        color: "#FAFAF9",
        fontSize: "12px",
        fontWeight: 600,
        fontFamilt: "Poppins",
        lineHeight: "24px",
        width: "70px",
        textAlign: "center"
    },
    "& .image": {
        width: "195px",
        height: "100%",
        borderRadius: "8px",
        objectFit: 'cover',
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    "& .cartBox": {
        width: "90%",
        margin: 'auto',
        display: "flex",
        justifyContent: "space-between",
        padding: "30px 0px"
    },
    '& .detailsSection': {
        display: 'flex',
        flexDirection: "column",
        gap: "35px",
        paddingTop: "10px"
    },
    "& .detailsBox": {
        display: "flex",
        gap: "24px"
    },
    "& .cartBtn": {
        display: 'flex',
        gap: "32px"
    },
    "& .deleteBtn": {
        color: '#DC2626',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        "& span": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Poppins",
            lineHeight: "22px",
            textTransform: "capitalize"
        }
    },
    "& .shareBtn": {
        color: '#57534E',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        "& span": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Poppins",
            lineHeight: "22px",
            textTransform: "capitalize"
        }
    },
    "& .productName": {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Poppins",
        lineHeight: "24px",
        color: "#292524",
        textTransform: 'capitalize',
    },
    "& .location": {
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins",
        lineHeight: "22px",
        color: "#78716C",
        textTransform: "capitalize",
    },
    '& .deliver': {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "22px",
    },
    "& .couponHead": {
        fontSize: "20px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "32px",
        color: "#000000"
    },
    "& .offerDetails": {
        display: 'flex',
        alignItems: 'center'
    },
    "& .space-between": {
        justifyContent: "space-between",
    },
    "& .status": {
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Poppins",
        lineHeight: "24px",
        color: "#F59E0B"
    },
    "& .saved": {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "24px",
        color: "#10B981",
        paddingLeft: "40px"
    },
    "& .discountOpt": {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins",
        lineHeight: "24px",
        color: "#78716C"
    },
    "& .offerSection": {
        display: 'flex',
        flexDirection: "column",
        gap: "25px"
    },
    "& .proceedBtn": {
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            background: "#7F1287"
        },
        "&:disabled": {
            background: "#808080",
            color: "#ababab",
        }
    },
    "& .userName": {
        display: 'flex',
        gap: "16px",
        alignItems: "center",
    },
    "& .homeBtn": {
        color: "#059669",
        textTransform: "capitalize",
        border: "1px solid #059669",
        padding: "0px 5px"
    },
    "& .userAdd": {
        display: 'flex',
        flexDirection: "column",
        gap: "20px"
    },
    '& .quantity': {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "inter",
        lineHeight: "22px",
    },
    '& .addNewAddressButton': {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#7F1187",
        marginTop: "20px",
        cursor: "pointer",
    },
    '& .required': {
        color: '#DC2626',
        marginLeft: '5px',
    },
    '& .knowMoreText': {
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#DC2626',
        marginLeft: '10px',
    },
    '& .likeIconBox': {
        position: "absolute",
        top: "0.5rem",
        right: "0.5rem",
        zIndex: 1,
    },
    '& .cardsImg': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
    },
    '& .productTitleRatingBox': {
        display: "flex",
        justifyContent: "space-between",
    },
    '& .productTitle': {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: 'Poppins',
        '@media (max-width: 768px)': { fontSize: '1rem' },
        '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .ratingBox': {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
    },
    '& .rating': {
        fontSize: "12px",
        fontFamily: 'Poppins',
        color: "#78716C",
        '@media (max-width: 480px)': { fontSize: '0.6rem' },
        '@media (max-width: 768px)': { fontSize: '0.7rem' },
    },
    '& .productLocationText': {
        paddingTop: "1rem",
        fontFamily: 'Poppins',
        '@media (max-width: 768px)': { paddingTop: "0.5rem" },
        '@media (max-width: 480px)': { fontSize: '0.8rem' },
    },
    '& .productPriceText': {
        fontWeight: 600,
        fontSize: "16px",
        color: "#801188",
        fontFamily: 'Poppins',
        '@media (max-width: 768px)': { fontSize: "14px" },
        '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .productSectionTitle': {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "24px",
        color: "#44403C",
        fontFamily: 'Poppins',
        marginTop: "30px",
        marginBottom: "30px",
        paddingLeft: "100px",
    },
    '& .dropdownErrorMsg': {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        margin: "8px 0px 0px",
        color: "D32F2F",
    },
    "& .viewAllButton": {
        color: "#7F1187",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#FFFFFF",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "207px",
        cursor: "pointer",
        border: "1px solid #7F1187",
        "&:hover": {
            background: "#FFFFFF"
        }
    },
});
// Customizable Area End
