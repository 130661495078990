import React from "react";
import {
  Box, InputAdornment,
  Button, Autocomplete, ListItemText,
  InputLabel, Checkbox, Stack, TextField, IconButton,
  Typography, styled, Container, Divider, MenuItem,
  Popover,
  Select
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Loader from "../../../components/src/AppLoader.web";
import TextInputFiled from "../../../components/src/TextInputField.web";
import HeaderVendorFooter from "../../../components/src/HeaderVendorFooter";
import { Formik, FieldArray, Field, FormikErrors } from "formik";
import * as Yup from "yup";
import CollectionsIcon from '@mui/icons-material/Collections';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import SelectField from "../../../components/src/SelectField.web";
const arrowImg = require('../assets/arrow.png')
const deleteIcon = require('../assets/deleteIcon.png')
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

interface Options {
  value: number;
  label: string;
}
// Customizable Area End
import AddCatalogueFormController, {
  Props, ColorOption, Item, Size, ColorSet, QuesSet
} from './AddCatalogueFormController.web'
const validationSchema = Yup.object().shape({
  productName: Yup.string().required("Product Name is required").trim()
    .matches(/^[a-zA-Z\s]+$/, "Product Name should only contain letters")
    .test(
      "not-only-digits",
      "Product Name cannot contain only digits",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 50 characters are allowed"),
  skuCode: Yup.string(),
  productCategory: Yup.string().required("Product Category is required"),
  productDescription: Yup.string().required("Product Description is required"),
  fabricDetails: Yup.string(),
  price: Yup.number().required("Price is required"),
  availableQuantity: Yup.number().required("Quantity is required"),
  coupons: Yup.string(),
  // .required("Please select Coupon"),
  selectFunction: Yup.string(),
  // .required("Please select Function"),
  selectedLocation: Yup.string(),
  // .required("Please select Location"),
  customTitle: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Title should only contain letters")
    .test(
      "not-only-digits",
      "Product Name cannot contain only digits",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 40 characters are allowed"),
  customDescription: Yup.string()
    .max(50, "Maximum 500 characters are allowed"),
  selectedFileName: Yup.mixed()
    .test(
      'fileSize',
      'File too large (Max 10MB)',
      value => value ? value.size < 10 * 1024 * 1024 : true
    )
    .test(
      'fileFormat',
      'Unsupported Format (only JPG, PNG)',
      value => value ? ['image/jpeg', 'image/png'].includes(value.type) : true
    ),
  productImages: Yup.array()
    .max(10, 'You can upload a maximum of 10 images')


});

export default class AddCatalogueForm extends AddCatalogueFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  isError = (
    touched: boolean | undefined,
    errors: string | undefined
  ) => {
    return touched && !!errors;
  };
  helperText = (
    touched: boolean | undefined,
    errors: any,
  ) => {
    return touched && errors;
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const coupons = [{ label: 'off500', value: 1 }, { label: "off2000", value: 2 }]
    const {
      sizeLabel,
      sizeLabelError,
      sizeLabelMsg,
      sizeMeasurement,
      sizeMeasurementError,
      sizeMeasurementMsg,
      availableSize,
      colorName,
      colorNameError,
      colorNameMsg,
      colorImage,
      colorImageMsg,
      colorImageError,
      availableColor,
      colorPopupAnchor,
      openColorPopup,
      displayColorImage,
      variantSize,
      variantSizeError,
      variantSizeMsg,
      variantColor,
      variantColorError,
      variantColorMsg,
      variantQuantity,
      variantQuantityError,
      variantQuantityMsg,
      availableVariants,
    } = this.state;

    return (
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <AddCatlogueMainStyle component="section" id="addcatalogueItems">
            <Typography className="addCatlogueText" component="h3">
              {this.state.IsEditing ? "Edit Catalogue" : "Add Catalogue"}
            </Typography>
            <Divider className="dividerStyle" />
            <Box className="mainCatalogueBox">
              <Typography className="headText">
                Product Details
              </Typography>
              <Formik
                data-test-id="Formik"
                initialValues={this.state.formData}
                validationSchema={validationSchema}
                onSubmit={(values) => this.handleProceedApiCAll(values)}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Box className="mainForm">
                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Product Name  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="productName"
                              data-test-id="productName-input"
                              placeholder="Enter your Product Name"
                              value={values.productName}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text"
                              error={this.isError(touched?.productName, errors?.productName)}
                              helperText={this.helperText(
                                touched?.productName,
                                errors?.productName
                              )}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">SKU</InputLabel>
                            <TextInputFiled
                              name="skuCode"
                              data-test-id="skuCode-input"
                              placeholder="R-185"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.skuCode} helperText={undefined} />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Product Category  <span className="star">*</span></InputLabel>
                            <SelectField
                              name="productCategory"
                              data-test-id="monthCheck"
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              options={this.state.productCategory}
                              placeholder="Enter your Product Name"
                              label={""} value={values.productCategory} error={Boolean(this.handleErrorText(touched?.productCategory, errors?.productCategory))}
                              touched={touched.productCategory}
                              helperText={this.handleErrorText(touched?.productCategory, errors?.productCategory)} />
                          </Box>
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Product Description  <span className="star">*</span></InputLabel>
                          <TextInputFiled
                            name="productDescription"
                            data-test-id="full-name-input"
                            placeholder="Enter your Product Description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={values.productDescription} error={this.isError(touched?.productDescription, errors?.productDescription)}
                            helperText={this.helperText(
                              touched?.productDescription,
                              errors?.productDescription
                            )}
                          />
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Fabric details</InputLabel>
                          <TextInputFiled
                            name="fabricDetails"
                            data-test-id="full-name-input"
                            placeholder="Enter Details of fabric"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text" value={values.fabricDetails} error={this.isError(touched?.fabricDetails, errors?.fabricDetails)}
                            helperText={this.helperText(
                              touched?.fabricDetails,
                              errors?.fabricDetails
                            )}
                          />
                        </Box>

                        <Box className="inputFirstSection">
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Price  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="price"
                              data-test-id="full-name-input"
                              placeholder="Enter Product Price"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.price} error={this.isError(touched?.price, errors?.price)}
                              helperText={this.helperText(
                                touched?.price,
                                errors?.price
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CurrencyRupeeIcon fontSize="small" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box className="inputFieldsSection">
                            <InputLabel className="labelClass">Available Quantity  <span className="star">*</span></InputLabel>
                            <TextInputFiled
                              name="availableQuantity"
                              data-test-id="full-name-input"
                              placeholder="Enter Available Quantity(in stock)"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              type="text" value={values.availableQuantity} error={this.isError(touched?.availableQuantity, errors?.availableQuantity)}
                              helperText={this.helperText(
                                touched?.availableQuantity,
                                errors?.availableQuantity
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Units
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>

                        </Box>

                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Product Images</InputLabel>
                          <TextInputFiled
                            name="productImages"
                            data-test-id="productImagesData"
                            placeholder="Upload a Product Image (Max 10 MB, JPG, PNG, High resolution images)"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={
                              Array.isArray(values.productImages) ?
                                values.productImages
                                  .map((file: { filename: string; url?: string }) => file.filename || '')
                                  .filter(Boolean)
                                  .join(', ')
                                : ''
                            }
                            type="text"
                            disabled
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment position="end" className="inputIcons">
                                  <CollectionsIcon
                                    onClick={this.handleProductIconClick}
                                    data-test-id="handleProductIconClick" />
                                </InputAdornment>
                              ),
                            }}
                            error={
                              Boolean(
                                touched.productImages &&
                                (errors.productImages)
                              )
                            }
                            helperText={
                              touched.productImages &&
                              (errors.productImages) as any
                            }
                          />

                          <input
                            id="hiddenProductFileInput"
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/jpeg, image/png"
                            multiple
                            data-test-id="handleProductFileChange"
                            onChange={(event) => this.handleFileChangeProduct(event, values, setFieldValue)}
                          />
                        </Box>
                        <Box className="imagePreview">
                          {values.productImages?.map((file: any, index: number) => (
                            <Box key={index} className="imageSet" style={{ position: 'relative', margin: '10px' }}>
                              <img
                                src={
                                  (file as { filename: string; url?: string }).url ||
                                  (file instanceof Blob ? URL.createObjectURL(file) : "")
                                }

                                style={{
                                  width: '200px',
                                  height: '180px',
                                  objectFit: 'cover',
                                  borderRadius: '8px',
                                }}
                              />
                              <img
                                src={deleteIcon}
                                className="deleteIconStyle"
                                onClick={() => this.handleDeleteImage(index, values, setFieldValue)}
                                data-test-id="delete-image"
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          ))}
                        </Box>

                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">
                            Function<span className="star">*</span>
                          </InputLabel>
                          <div>
                            <Autocomplete
                              multiple
                              id="tags-checkbox"
                              options={this.state.functionList}
                              data-test-id="handleFunctionSelectChange"
                              disableCloseOnSelect
                              value={this.state.selectedFunctions}
                              onChange={(event, newValue) => this.handleFunctionSelectChange(event, newValue, setFieldValue)}
                              renderTags={() => null}
                              renderOption={(props, option) => {
                                const isSelected = this.state.selectedFunctions.some(
                                  (selected) => selected.value == option.value
                                );
                                return (
                                  <MenuItem
                                    {...props}
                                    key={option.value}
                                    onClick={() => {
                                      this.toggleFunctionOption(option);
                                      this.handleFunctionOption(option, isSelected, setFieldValue);
                                    }}
                                    style={{ borderBottom: '1px solid #E7E5E4' }}
                                  >
                                    <Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                                    <ListItemText primary={option.label} />
                                  </MenuItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Functions for Outfits"
                                  data-test-id="handleFunctionSet"
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.selectFunction}
                                  error={this.isError(touched?.selectFunction, errors?.selectFunction)}
                                  helperText={this.helperText(touched?.selectFunction, errors?.selectFunction)}
                                />
                              )}
                            />
                          </div>

                          <Stack direction="row" spacing={1} className="chipBox">
                            {this.state.selectedFunctions?.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                style={{ margin: '4px' }}
                                data-test-id="handleDeleteFunctionChip"
                                onDelete={() => this.handleDeleteFunctionChip(option, setFieldValue)}
                                deleteIcon={
                                  <IconButton size="small">
                                    <CloseIcon fontSize="small" className="closeIcon" />
                                  </IconButton>
                                }
                              />
                            ))}
                          </Stack>
                        </Box>


                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Service Location<span className="star">*</span></InputLabel>
                          <div>
                            <Autocomplete
                              multiple
                              id="tags-checkbox"
                              options={this.state.locationData}
                              value={this.state.selectedLocations}
                              data-test-id="handleSelectLocationChange"
                              onChange={(event, newValue) => this.handleSelectLocationChange(event, newValue, setFieldValue)}
                              disableCloseOnSelect
                              renderTags={() => null}
                              renderOption={(props, option: ColorOption) => {
                                const isSelected = this.state.selectedLocations.some(selected => selected.value === option.value);
                                return (
                                  <MenuItem
                                    {...props}
                                    key={option.value}
                                    onClick={() => {
                                      this.toggleLocationOption(option);
                                      this.handleLocationOption(option, isSelected, setFieldValue);
                                    }}
                                    style={{ borderBottom: '1px solid #E7E5E4' }}
                                  ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                                    <ListItemText
                                      primary={
                                        option.label}
                                    /></MenuItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  data-test-id="handleChangeLocation"
                                  placeholder="Select the location where you can deliver your products for Outfit"
                                  onChange={(event) => handleChange(event)}
                                  onBlur={handleBlur}
                                  value={values.selectedLocation}
                                  error={this.isError(touched?.selectedLocation, errors?.selectedLocation)}
                                  helperText={this.helperText(
                                    touched?.selectedLocation,
                                    errors?.selectedLocation
                                  )}
                                />
                              )}
                            />
                          </div>

                          <Stack direction="row" spacing={1} className="chipBox">
                            {this.state.selectedLocations?.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                style={{ margin: '4px' }}
                                data-test-id="handleDeleteLocationChip"
                                onDelete={() => this.handleDeleteLocationChip(option, setFieldValue)}
                                deleteIcon={
                                  <IconButton size="small">
                                    <CloseIcon fontSize="small" className="closeIcon" />
                                  </IconButton>
                                }

                              />
                            ))}
                          </Stack>
                        </Box>

                        <Box>
                          <Box className="faqAskSection" marginBottom={"15px"}>
                            <Typography className="faqAsk">Available Sizes<span className="star">*</span></Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                            <Box className="inputFirstSection" marginBottom={"15px"}>
                              <Box className="inputFieldsSection">
                                <InputLabel className="labelClass">Size Name</InputLabel>
                                <TextField
                                  name={`sizeLabel`}
                                  placeholder="Enter size"
                                  data-test-id="sizeName"
                                  value={sizeLabel}
                                  onChange={this.handleInputChange}
                                  onBlur={handleBlur}
                                  style={{ width: '100%' }}
                                  type="text"
                                  error={sizeLabelError}
                                  helperText={sizeLabelMsg}
                                />

                              </Box>
                              <Box className="inputFieldsSection">
                                <InputLabel className="labelClass">Measurements</InputLabel>
                                <TextField
                                  name={`sizeMeasurement`}
                                  data-test-id="full-name-input"
                                  placeholder="Enter value"
                                  value={sizeMeasurement}
                                  onChange={this.handleInputChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  style={{ width: '100%' }}
                                  error={sizeMeasurementError}
                                  helperText={sizeMeasurementMsg}
                                />
                              </Box>
                              <Button className="quesBtn" data-test-id="createSizeButton" type="button" onClick={() => this.createSizeApi(sizeLabel, sizeMeasurement)}>Save</Button>
                            </Box>
                          </Box>
                          {availableSize && availableSize.length > 0 ?
                            <Box className="sizeColorBadgeMainBox">
                              {availableSize.map((item) => (
                                <Box className="sizeColorBadge">
                                  <Typography className="badgeTitle">Size Name:
                                    <Typography className="badgeValue" component={"span"}>
                                      {item.label}
                                    </Typography>
                                  </Typography>
                                  <Typography className="badgeTitle">Measurment:
                                    <Typography className="badgeValue" component={"span"}>
                                      {item.value}
                                    </Typography>
                                  </Typography>
                                  <Typography data-test-id="deleteSizeButton" className="badgeDeleteIcon" onClick={() => this.handleDeleteSize(item.id)}> <DeleteIcon /> </Typography>
                                </Box>
                              ))}
                            </Box>
                            :
                            <Box className="sizeColorBadgeMainBox" width={"100%"}>
                              <Box className="noContentBadge">
                                <Typography className="badgeTitle">No Sizes Created!</Typography>
                              </Box>
                            </Box>
                          }
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Size Chart</InputLabel>
                          <TextInputFiled
                            name="selectedFileName"
                            data-test-id="sizeChart"
                            placeholder="Upload a Size Chart (Max 10 MB, JPG,PNG, High resolution images)"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            disabled
                            value={values.sizeChart || ''}
                            type="text"
                            error={this.isError(touched.selectedFileName, errors?.selectedFileName)}
                            helperText={this.helperText(
                              touched?.selectedFileName,
                              errors?.selectedFileName
                            )}
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment position="end" className="inputIcons">
                                  <CollectionsIcon onClick={this.handleIconClick} data-test-id="handleIconClick" />
                                </InputAdornment>
                              )
                            }}
                          />
                          <input
                            id="hiddenFileInput"
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/jpeg, image/png"
                            data-test-id="handleFileChange1"
                            onChange={(event) => this.handleFileChange(event, setFieldValue)}
                          />
                        </Box>
                        <Box className="imagePreview">
                          {values.selectedFileName && (

                            <Box className="imageSet">
                              <img
                                src={
                                  URL.createObjectURL(values.selectedFileName)
                                }
                                alt="Preview"
                                style={{
                                  width: '200px',
                                  height: '180px',
                                  objectFit: 'cover',
                                  borderRadius: '8px',
                                }}
                              />
                              <img
                                src={deleteIcon}
                                className="deleteIconStyle"
                                style={{ cursor: 'pointer' }}
                                data-test-id="handleSizeDeleteImage"
                                onClick={() => this.handleSizeDeleteImage(setFieldValue)}
                              />
                            </Box>
                          )}
                        </Box>
                        <Box>
                          <Box className="faqAskSection" marginBottom={"15px"}>
                            <Typography className="faqAsk">
                              Available Color<span className="star">*</span>
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                            <Box className="inputFirstSection">
                              <Box className="inputFieldsSection">
                                <InputLabel className="labelClass">Name of The Color</InputLabel>
                                <TextInputFiled
                                  name={`colorName`}
                                  data-test-id="colorNameInput"
                                  placeholder="Enter Name"
                                  handleChange={this.handleInputChange}
                                  handleBlur={handleBlur}
                                  type="text"
                                  value={colorName}
                                  error={colorNameError}
                                  helperText={colorNameMsg}
                                />
                              </Box>
                              <Box className="inputFieldsSection">
                                <InputLabel className="labelClass">Upload Image</InputLabel>
                                <TextField
                                  style={{ width: "100%" }}
                                  name={`colorImage`}
                                  data-test-id="currentUploadImage"
                                  placeholder="Upload Image (Max 2MB, jpg, png)"
                                  type="text"
                                  value={colorImage ? colorImage.name : ""}
                                  onBlur={handleBlur}
                                  disabled
                                  error={colorImageError}
                                  helperText={colorImageMsg}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment position="end" className="inputIcons">
                                        <CollectionsIcon
                                          onClick={() => document.getElementById(`hiddenColorFileInput`)?.click()}
                                          data-test-id="handleColorIconClick"
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <input
                                  id={`hiddenColorFileInput`}
                                  type="file"
                                  style={{ display: 'none' }}
                                  accept="image/jpeg, image/png"
                                  data-test-id="handleColorFileChange"
                                  onChange={(event) => this.handleColorFileChange(event)}
                                />

                              </Box>
                              <Button
                                data-test-id="createColorButton"
                                className="quesBtn"
                                type="button"
                                onClick={() => this.createAddColor(colorName, colorImage)}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                          {availableColor && availableColor.length > 0 ?
                            <Box className="sizeColorBadgeMainBox">
                              {availableColor.map((item) => (
                                <>
                                  <Box className="sizeColorBadge">
                                    <Typography data-test-id="handleMouseEnter" onClick={(event) => this.handleMouseEnter(event, item)} className="badgeTitle">Color:
                                      <Typography className="badgeValue" component={"span"}>
                                        {item.label}
                                      </Typography>
                                    </Typography>
                                    <Typography data-test-id="deleteColorButton" className="badgeDeleteIcon" onClick={() => this.handleDeleteColor(item.id)}> <DeleteIcon /> </Typography>
                                  </Box>
                                  <Popover
                                    data-test-id="popoverHandleMouseLeave"
                                    disableAutoFocus
                                    disableEnforceFocus
                                    disableRestoreFocus
                                    open={openColorPopup}
                                    anchorEl={colorPopupAnchor}
                                    onClose={this.handleMouseLeave}
                                    anchorOrigin={{
                                      vertical: 35,
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 0,
                                      horizontal: 0,
                                    }}
                                    PaperProps={{
                                      onMouseLeave: this.handleMouseLeave,
                                    }}
                                  >
                                    <img
                                      src={displayColorImage}
                                      alt="Preview"
                                      style={{ width: '150px', height: '150px', objectFit: "cover" }}
                                    />
                                  </Popover>
                                </>
                              ))}
                            </Box>
                            :
                            <Box className="sizeColorBadgeMainBox" width={"100%"}>
                              <Box className="noContentBadge">
                                <Typography className="badgeTitle">No Colors Created!</Typography>
                              </Box>
                            </Box>
                          }
                        </Box>
                        <Box>
                          <Box className="faqAskSection" marginBottom={"15px"}>
                            <Typography className="faqAsk">
                              Create Variant<span className="star">*</span>
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 3, marginBottom: "15px" }}>
                            <Box className="inputFirstSection">
                              <Box className="inputFieldsSection">
                                <InputLabel className="labelClass">Available Size</InputLabel>
                                <Select
                                  data-test-id="variantSizeDropdown"
                                  placeholder={"Select Size"}
                                  name={"variantSize"}
                                  onChange={this.handleDropdownChange}
                                  onBlur={handleBlur}
                                  value={variantSize}
                                  style={{ width: "100%" }}
                                  error={variantSizeError}
                                >
                                  <MenuItem disabled>
                                    Select Color
                                  </MenuItem>
                                  {availableSize && availableSize.map((item) => (
                                    <MenuItem value={item.id}>{item.label} - {item.value}</MenuItem>
                                  ))}
                                </Select>
                                {variantSizeError && <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>{variantSizeMsg}</Typography>}
                              </Box>
                              <Box className="inputFieldsSection">
                                <InputLabel className="labelClass">Available Color</InputLabel>
                                <Select
                                  placeholder={"Select Size"}
                                  name={"variantColor"}
                                  onChange={this.handleDropdownChange}
                                  onBlur={handleBlur}
                                  value={variantColor}
                                  style={{ width: "100%" }}
                                  error={variantColorError}
                                >
                                  <MenuItem disabled>
                                    Select Color
                                  </MenuItem>
                                  {availableColor && availableColor.map((item) => (
                                    <MenuItem value={item.id}>{item.label}</MenuItem>
                                  ))}
                                </Select>
                                {variantColorError && <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>{variantColorMsg}</Typography>}
                              </Box>
                              <Box className="inputFieldsSection">
                                <InputLabel className="labelClass">Enter Quantity</InputLabel>
                                <TextField
                                  name={`variantQuantity`}
                                  placeholder="Enter Quantity"
                                  onChange={this.handleInputChange}
                                  onBlur={handleBlur}
                                  value={variantQuantity}
                                  error={variantQuantityError}
                                  helperText={variantQuantityMsg}
                                  style={{ width: "100%" }}
                                />
                              </Box>
                              <Button
                                data-test-id="createVariantButton"
                                className="quesBtn"
                                type="button"
                                onClick={() => this.createVariant(variantSize, variantColor, variantQuantity)}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                          {availableVariants && availableVariants.length > 0 ?
                            <Box className="sizeColorBadgeMainBox">
                              {availableVariants.map((item) => (
                                <Box className="sizeColorBadge">
                                  <Typography className="badgeTitle">Color:
                                    <Typography className="badgeValue" component={"span"}>
                                      {item.color.name}
                                    </Typography>
                                  </Typography>
                                  <Typography className="badgeTitle">Size Name:
                                    <Typography className="badgeValue" component={"span"}>
                                      {item.size.size_label}
                                    </Typography>
                                  </Typography>
                                  <Typography className="badgeTitle">Measurement:
                                    <Typography className="badgeValue" component={"span"}>
                                      {item.size.measurement}
                                    </Typography>
                                  </Typography>
                                  <Typography className="badgeTitle">Quantity:
                                    <Typography className="badgeValue" component={"span"}>
                                      {item.quantity}
                                    </Typography>
                                  </Typography>
                                  <Typography data-test-id="deleteVariantButton" className="badgeDeleteIcon" onClick={() => this.handleDeleteVariant(item.id)}> <DeleteIcon /> </Typography>
                                </Box>
                              ))}
                            </Box>
                            :
                            <Box className="sizeColorBadgeMainBox" width={"100%"}>
                              <Box className="noContentBadge">
                                <Typography className="badgeTitle">No Variants Created!</Typography>
                              </Box>
                            </Box>
                          }
                        </Box>
                        <Box>
                          <FieldArray name="items" data-test-id="FieldArray3">
                            {({ push, remove }: { push: (obj: Item) => void; remove: (index: number) => void }) => (
                              <>
                                <Box className="faqAskSection">
                                  <Typography className="faqAsk">Custom Field</Typography>
                                  <Button className="quesBtn" data-test-id="itemQuesBtn" onClick={() => push({ title: "", description: "" })}>+ Add Field</Button>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                  {values.items.map((_, index) => (
                                    <Box className="inputFirstSection">
                                      <Box className="inputFieldsSection">
                                        <InputLabel className="labelClass">Title</InputLabel>
                                        <TextField
                                          name={`items[${index}].title`}
                                          data-test-id="full-name-input"
                                          placeholder="Enter Title"
                                          style={{ width: "100%" }}
                                          value={values.items[index].title}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          type="text"
                                        />
                                      </Box>
                                      <Box className="inputFieldsSection">
                                        <InputLabel className="labelClass">Description</InputLabel>
                                        <TextField
                                          name={`items[${index}].description`}
                                          data-test-id="full-name-input"
                                          style={{ width: "100%" }}
                                          placeholder="Type something..."
                                          value={values.items[index].description}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          type="text"
                                        />
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </>
                            )}
                          </FieldArray>
                        </Box>
                        <Box className="inputFieldsSection">
                          <InputLabel className="labelClass">Coupons<span className="star">*</span></InputLabel>
                          <SelectField
                            name="coupons"
                            data-test-id="monthCheck"
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            options={this.state.couponData || []}
                            placeholder="Enter your Product Name"
                            label={""} value={values.coupons} error={Boolean(this.handleErrorText(touched?.coupons, errors?.coupons))}
                            touched={touched.coupons}
                            helperText={this.handleErrorText(touched?.coupons, errors?.coupons)} />
                        </Box>

                        <FieldArray name="addQues" data-test-id="FieldArray4">
                          {({ push, remove }: { push: (obj: QuesSet) => void; remove: (index: number) => void }) => (
                            <>
                              <Box className="faqAskSection">
                                <Typography className="faqAsk">Frequently asked questions</Typography>
                                <Button className="quesBtn" data-test-id="questionBtn4" onClick={() => push({ Questions: "", answer: "", id: '' })}>+ Add Questions</Button>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                <InputLabel className="labelClass">Questions<span className="star">*</span></InputLabel>
                                {values.addQues.map((_, index) => (
                                  <Box className="inputFieldsSection">
                                    <TextField
                                      name={`addQues[${index}].Questions`}
                                      data-test-id="full-name-input"
                                      placeholder={`Q${index + 1}: Enter Your questions`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{ width: "100%" }}
                                      type="text" value={values.addQues[index].Questions}
                                    />
                                    <Box className="ansSection">
                                      <img src={arrowImg} alt="arrow" className="arrowStyle" />
                                      <TextField
                                        name={`addQues[${index}].answer`}
                                        data-test-id="full-name-input"
                                        placeholder="A: Enter Response"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text" value={values.addQues[index].answer}
                                      />
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </>
                          )}
                        </FieldArray>
                      </Box>
                      <Box className="Btns">
                        <Button className="cancelBtn">Cancel</Button>
                        <Button className="proceedBtn" type="submit" data-test-id="handleCreateCatalogoueApi">Proceed</Button>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </AddCatlogueMainStyle>
        </CustomContainer>
      </HeaderVendorFooter>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1280px)": {
    maxWidth: "95%",
  },
});

const AddCatlogueMainStyle = styled(Box)({
  padding: "32px 0 24px",
  overflowX: 'hidden',
  "& .addCatlogueText": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "left",
    color: "#292524"
  },
  "& .dividerStyle": {
    backgroundColor: "#A8A29E",
    height: "1px",
    marginTop: "24px",
    marginBottom: "30px",
    width: "94%"
  },
  "& .mainCatalogueBox": {
    border: "1px solid #D6D3D1",
    borderRadius: '16px',
    backgroundColor: "#FFFFFF",
    padding: "30px 28px"
  },
  "& .headText": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
    color: "#292524",
  },
  "& .labelClass": {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
  },
  "& .star": {
    color: "#DC2626 !important",
  },
  "& .inputFirstSection": {
    display: 'flex',
    width: "100%",
    gap: "26px"
  },
  "& .inputFieldsSection": {
    width: '100%'
  },
  "& .mainForm": {
    width: '100%',
    paddingTop: "28px",
    paddingBottom: "80px",
    display: "flex",
    flexDirection: 'column',
    gap: "32px"
  },
  "& .cancelBtn": {
    color: "#94A3B8",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#E2E8F0",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#E2E8F0"
    }
  },
  "& .proceedBtn": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "227px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    }
  },
  "& .Btns": {
    display: "flex",
    width: '100%',
    justifyContent: "flex-end",
    gap: "24px"
  },
  "& .selectOption": {
    color: "#0F172A",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
  },
  "& .Mui-checked": {
    color: "#6200EA !important",
  },
  "& .MuiCheckbox-root": {
    borderRadius: "6px !important",
  },
  "& .faqAskSection": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  "& .faqAsk": {
    color: "#292524",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
  },
  "& .quesBtn": {
    cursor: "pointer",
    color: "#7F1187",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "26px",
    textTransform: "capitalize",
  },
  "& .ansSection": {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: "100%",
    "& > *:nth-child(2)": {
      width: "100%",
    },
  },
  "& .arrowStyle": {
    marginTop: "--2px",
    marginLeft: "15px"
  },
  "& .MuiChip-root": {
    border: "2px solid #CA1A7D",
    backgroundColor: "transparent",
    padding: '6px, 8px, 6px, 12px'
  },
  "& .MuiChip-label": {
    color: "#292524",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textTransform: "capitalize"
  },
  "& .closeIcon": {
    color: "black"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .chipBox": {
    marginTop: "20px"
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .imagePreview": {
    display: "flex",
    gap: "8px",
    width: "80%",
    flexWrap: "wrap"
  },
  "& .errorStyle": {
    margin: " 8px 0px 0px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "rgb(211, 47, 47)"
  },
  "& .inputIcons": {
    cursor: "pointer"
  },
  "& .imageSet": {
    position: "relative",
  },
  "& .deleteIconStyle": {
    position: 'absolute',
    right: "10px",
    top: '10px'
  },
  "& .sizeColorBadgeMainBox": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px"
  },
  "& .sizeColorBadge": {
    width: "max-content",
    borderRadius: "8px",
    background: "#CA1A7D33",
    display: "flex",
    flexDirection: "row",
    columnGap: "20px",
    padding: "5px 10px"
  },
  "& .noContentBadge": {
    width: "100%",
    borderRadius: "8px",
    background: "#CA1A7D33",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #334155",
    borderStyle: "dashed",
  },
  "& .badgeTitle": {
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#334155"
  },
  "& .badgeValue": {
    color: "#801187",
    marginLeft: "5px",
  },
  "& .badgeDeleteIcon": {
    fontSize: "12px",
    color: "#DC2626",
    cursor: "pointer",
  },
});
// Customizable Area End
