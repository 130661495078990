import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../packages/components/src/utils";
import moment from 'moment';

// Customizable Area Start
interface DropdownData {
  id: number,
  label: string,
  value: string,
  code?: string,
}

interface UserProfileResponse {
  data: {
    id: string;
    type: string;
    attributes: UserProfileAttributes;
  };
  error: string;
}

interface UserProfileAttributes {
  email: string;
  full_name: string;
  language: string | null;
  gender: string | null;
  date_of_birth: string | null;
  address: Address;
  profile_picture: string;
}

interface Address {
  id: string;
  type: string;
  attributes: {
    latitude: number | null;
    longitude: number | null;
    address: string;
    address_type: string;
    country: string;
    city: string;
    state: string;
    pincode: string;
    service_locations: any[];
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  role: string;
  fullName: string;
  fullNameError: boolean;
  fullNameMsg: string;
  gender: string;
  genderError: boolean;
  genderMsg: string;
  language: string;
  languageError: boolean;
  languageMsg: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  dobMonthError: boolean,
  dobDayError: boolean,
  dobYearError: boolean,
  dobMsg: string;
  formAddress: string;
  formCity: string;
  formState: string;
  formCountry: string;
  formPincode: string;
  addressError: boolean;
  cityError: boolean;
  stateError: boolean;
  countryError: boolean;
  pincodeError: boolean;
  addressErrorMsg: string;
  cityErrorMsg: string;
  stateErrorMsg: string;
  countryErrorMsg: string;
  pincodeErrorMsg: string;
  countries: DropdownData[];
  states: DropdownData[];
  cities: DropdownData[];
  genders: DropdownData[];
  languages: DropdownData[];
  months: DropdownData[];
  days: DropdownData[];
  years: DropdownData[];
  isEditing: boolean;
  user: UserProfileAttributes | null;
  formattedDOB: string;
  apiErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserBioController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileId: string = "";
  getCountriesId: string = "";
  getCitiesId: string = "";
  getStatesId: string = "";
  saveUserDataApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      role: '',
      fullName: '',
      fullNameError: false,
      fullNameMsg: '',
      gender: '',
      genderError: false,
      genderMsg: '',
      language: '',
      languageError: false,
      languageMsg: '',
      dobMonth: '',
      dobDay: '',
      dobYear: '',
      dobMonthError: false,
      dobDayError: false,
      dobYearError: false,
      dobMsg: '',
      formAddress: '',
      formCity: ' ',
      formState: ' ',
      formCountry: ' ',
      formPincode: '',
      addressError: false,
      cityError: false,
      stateError: false,
      countryError: false,
      pincodeError: false,
      addressErrorMsg: '',
      cityErrorMsg: '',
      stateErrorMsg: '',
      countryErrorMsg: '',
      pincodeErrorMsg: '',
      isEditing: false,
      countries: [],
      states: [],
      cities: [],
      months: [],
      days: [],
      years: [],
      user: null,
      formattedDOB: '',
      apiErrorMsg: '',
      genders: [
        {
          id: 1,
          label: 'Male',
          value: '0',
        },
        {
          id: 2,
          label: 'Female',
          value: '1',
        },
        {
          id: 3,
          label: 'Others',
          value: '2',
        }
      ],
      languages: [
        {
          id: 1,
          label: 'English',
          value: 'english',
        },
        {
          id: 2,
          label: 'Hindi',
          value: 'hindi',
        }
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.getCountriesId:
          this.handleGetCountriesResponse(webResponseJson);
          break;

        case this.getCitiesId:
          this.handleGetCitiesResponse(webResponseJson);
          break;

        case this.getStatesId:
          this.handleGetSattesResponse(webResponseJson);
          break;

        case this.getUserProfileId:
          this.handleGetUserProfileResponse(webResponseJson);
          break;

        case this.saveUserDataApiId:
          this.handleSaveUserDataResponse(webResponseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setUserRole();
    this.setDayMonthYear();
    this.getCountries();
    this.getUserProfileData();
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  setUserRole = async () => {
    let userRole: string = await getStorageData("role");
    if (userRole) {
      this.setState({ role: userRole.toLocaleLowerCase() });
    }
  }

  renderStringValueDropdown = (value: string, dropdownData: DropdownData[]) => {
    return dropdownData.find((item) => item.value === value)?.label;
  }

  handleInputChange = (event: any) => {
    const { name, value } = event.target;

    if (name === 'formPincode' && value.length > 6) {
      return false;
    }

    if (name === 'formContactNo' && value.length > 10) {
      return false;
    }

    if (value) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }), () => {
        switch (name) {
          case 'formAddress':
            this.setState({ addressError: false, addressErrorMsg: '' });
            break;

          case 'formCity':
            this.setState({ cityError: false, cityErrorMsg: '' });
            break;

          case 'formState':
            this.setState({ stateError: false, stateErrorMsg: '' });
            this.getCities();
            break;

          case 'formCountry':
            this.setState({ countryError: false, countryErrorMsg: '' });
            this.getStates();
            break;

          case 'formPincode':
            this.setState({ pincodeError: false, pincodeErrorMsg: '' });
            break;

          default:
            break;
        }
      });

    }
  }

  setDayMonthYear = () => {
    const months = [
      { id: 1, label: 'January', value: '1' },
      { id: 2, label: 'February', value: '2' },
      { id: 3, label: 'March', value: '3' },
      { id: 4, label: 'April', value: '4' },
      { id: 5, label: 'May', value: '5' },
      { id: 6, label: 'June', value: '6' },
      { id: 7, label: 'July', value: '7' },
      { id: 8, label: 'August', value: '8' },
      { id: 9, label: 'September', value: '9' },
      { id: 10, label: 'October', value: '10' },
      { id: 11, label: 'November', value: '11' },
      { id: 12, label: 'December', value: '12' },
    ];

    const days: DropdownData[] = [];
    Array.from({ length: 31 }).forEach((_, index) => {
      days.push({
        id: index + 1,
        label: `${index + 1}`,
        value: `${index + 1}`,
      });
    });

    const startYear = 1950;
    const currentYear = new Date().getFullYear();
    const years: DropdownData[] = [];
    Array.from({ length: currentYear - startYear + 1 }).forEach((_, index) => {
      const year = startYear + index;
      years.push({
        id: index + 1,
        label: `${year}`,
        value: `${year}`,
      });
    });

    this.setState({ months, days, years });
  }

  getUserProfileData = async () => {
    this.setState({ isLoading: true });
    this.getUserProfileId = await apiCall({
      endPoint: configJSON.userBioProfileEndpoint,
      method: configJSON.validationApiMethodType,
    });
  };

  handleGetUserProfileResponse = (response: UserProfileResponse) => {
    if (response?.data) {
      const user = response.data.attributes;
      const address = response.data.attributes.address ? response.data.attributes.address.attributes : null;
      const gender = user.gender;
      const genderValue = this.state.genders.find((item) => item.label.toLocaleLowerCase() === gender?.toLocaleLowerCase())?.value;
      const dateString = user?.date_of_birth || '';
      let formattedDOB = "";
      let day = 0;
      let month = 0;
      let year = 0;

      if (dateString) {
        const date = new Date(dateString);

        day = date.getDate();
        month = date.getMonth() + 1;
        year = date.getFullYear();

        formattedDOB = moment(dateString).format('ddd, DD MMMM YYYY');
      }

      if (user) {
        this.setState({
          user,
          fullName: user.full_name,
          gender: genderValue || '',
          language: user.language || '',
          formAddress: address?.address || '',
          formCountry: address?.country || '',
          formState: address?.state || '',
          formCity: address?.city || '',
          formPincode: address?.pincode || '',
          dobDay: day.toString(),
          dobMonth: month.toString(),
          dobYear: year.toString(),
          formattedDOB,
        }, () => {
          this.getStates();
        })
      }
    }
    if (response.error) {
      toast.error(response.error);
    }
    this.setState({ isLoading: false });
  }

  getCountries = async () => {
    this.setState({ isLoading: true });
    this.getCountriesId = await apiCall({
      endPoint: configJSON.getCountryCodeEndpoint,
      method: configJSON.validationApiMethodType,
    });
  };

  handleGetCountriesResponse = (response: { data: { country_full_name: string, country_code: string }[], error: string }) => {
    if (response?.data) {
      const countries = response.data.map((item, index) => {
        return {
          id: index + 1,
          label: item.country_full_name,
          value: item.country_code,
          code: item.country_code,
        }
      });
      this.setState({ countries });
    } else {
      this.setState({ countries: [] });
    }
    if (response.error) {
      toast.error(response.error);
    }
    this.setState({ isLoading: false });
  }

  getStates = async () => {
    this.setState({ isLoading: true });
    const { formCountry, countries } = this.state;
    const countryCode = countries.find((item) => item.value === formCountry)?.code;
    this.getStatesId = await apiCall({
      endPoint: configJSON.getStatesEndpoint + `?country_code=${countryCode}`,
      method: configJSON.validationApiMethodType,
    });
  };

  handleGetSattesResponse = (response: { data: { state_code: string, state_name: string }[], error: string }) => {
    if (response?.data) {
      const states = response.data.map((item, index) => {
        return {
          id: index + 1,
          label: item.state_name,
          value: item.state_name,
          code: item.state_code,
        }
      });
      this.setState({ states }, () => {
        this.getCities();
      });
    } else {
      this.setState({ states: [] });
    }
    if (response.error) {
      this.setState({ apiErrorMsg: response.error });
    }
    this.setState({ isLoading: false });
  }

  getCities = async () => {
    this.setState({ isLoading: true });
    const { formCountry, countries, formState, states } = this.state;
    const countryCode = countries.find((item) => item.value === formCountry)?.code;
    const stateCode = states.find((item) => item.value === formState)?.code;

    this.getCitiesId = await apiCall({
      endPoint: configJSON.getCitiesEndpoint + `?country_code=${countryCode}&state_code=${stateCode}`,
      method: configJSON.validationApiMethodType,
    });
  };

  handleGetCitiesResponse = (response: { data: { city_name: string }[], error: string }) => {
    if (response?.data) {
      const cities = response.data.map((item, index) => {
        return {
          id: index + 1,
          label: item.city_name,
          value: item.city_name,
        }
      });
      this.setState({ cities });
    } else {
      this.setState({ cities: [] });
    }
    if (response.error) {
      this.setState({ apiErrorMsg: response.error });
    }
    this.setState({ isLoading: false });
  }

  editUserInfo = () => {
    this.setState({ isEditing: true });
  }

  saveUserData = async () => {
    if (!this.checkFormValidation()) {
      return false;
    }

    const {
      fullName,
      gender,
      dobDay,
      dobMonth,
      dobYear,
      formAddress,
      formCountry,
      formState,
      formCity,
      formPincode,
    } = this.state;
    const dob = `${dobDay}/${dobMonth}/${dobYear}`;
    const requestBody = {
      full_name: fullName,
      gender: parseInt(gender),
      date_of_birth: dob,
      address: {
        address: formAddress,
        country: formCountry,
        city: formCity,
        state: formState,
        pincode: formPincode,
      }
    }

    this.setState({ isLoading: true });
    this.saveUserDataApiId = await apiCall({
      endPoint: configJSON.updateUserProfileEndpoint,
      contentType: 'application/json',
      method: configJSON.httpPatchMethod,
      body: JSON.stringify(requestBody),
    });
  }

  handleSaveUserDataResponse = (response: any) => {
    if (response?.data) {
      toast.success("Profile updated successfully");
      this.setState({ isEditing: false });
      this.props.navigation.navigate("LandingPageWeb");
      this.props.navigation.navigate("UserBio");
    }
    if (response.error) {
      toast.error(response.error);
    }
    this.setState({ isLoading: false });
  }

  checkFormValidation = (): boolean => {
    let isValid = true;
    const {
      formAddress,
      formCity,
      formState,
      formCountry,
      formPincode,
    } = this.state;

    if (!formAddress) {
      this.setState({ addressError: true, addressErrorMsg: 'Address is required' });
      isValid = false;
    }

    if (!formCity) {
      this.setState({ cityError: true, cityErrorMsg: 'City is required' });
      isValid = false;
    }

    if (!formState) {
      this.setState({ stateError: true, stateErrorMsg: 'State is required' });
      isValid = false;
    }

    if (!formCountry) {
      this.setState({ countryError: true, countryErrorMsg: 'Country is required' });
      isValid = false;
    }

    if (!formPincode) {
      this.setState({ pincodeError: true, pincodeErrorMsg: 'Pincode is required' });
      isValid = false;
    }

    return isValid;
  }
  // Customizable Area End
}
